
// COLORS-SCSS
@import 'colors';
// RESET-SCSS
@import 'reset';
// FONTS-SCSS
@import 'fonts';
// NAVBAR-SCSS
@import 'nav';
// FORM-SCSS
@import 'form';
// Responsive-SCSS
@import 'responsive';
// CUSTOM-SCSS
@import 'custom';

/* You can add global styles to this file, and also import other style files */


// HTML-BODY-SCSS
html,
body {
  height: 100%;
  scroll-behavior: smooth;
}
// BODY
body {
  margin: 0px;
  padding: 0px;
  font-family: $font-family;
  font-size: 14px;
  background: #FFFFFF;
  color: $primary-text;
  font-weight: 500;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  letter-spacing: 0.5px;
  outline: 0 !important;
}
p {
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 20px;

}
.sec-padding {
  padding: 25px 0 25px 0;
}

.row-wrap {
  flex-wrap: wrap;
}

section {
  clear: both;
}

.w-100 {
  width: 100%;
}

.flex-display {
  display: flex !important;
}

.block {
  display: block !important;
}

// HEADINGS
h1,
h2 {
  font-weight: 400;
  font-size: 26px;
  padding: 0;
  margin: 0;
}

h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  font-size: 16px;
}

p {
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 20px;

}

a {
  text-decoration: none;
}

ul {
  padding: 0px;
  list-style: none;
  margin: 0;
}

li {
  outline: 0;
}

// HEIGHTS
// .min-height {
//   min-height: calc(100vh - 215px);
//   max-width: 100%;
// }

// TEXT-ALIGNMENTS
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// PADDING-SCSS
.p-0 {
  padding: 0px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

// PADDING-TOP
.pt-0 {
  padding-top: 0px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

// PADDING-BOTTOM
.pb-0 {
  padding-bottom: 0px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

// PADDING-LEFT
.pl-0 {
  padding-left: 0px !important
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

// PADDING-RIGHT
.pr-0 {
  padding-right: 0px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

// MARGIN-SCSS
.mr-auto {
  margin-right: auto !important;
}

.m-0 {
  margin: 0px !important;
}

.m-30 {
  margin: 30px !important;
}

// MARGIN-TOP
.mt-0 {
  margin-top: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt--20 {
  margin-top: -20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

// MARGIN-BOTTOM
.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

// MARGIN-LEFT
.ml-0 {
  margin-left: 0px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

// MARGIN-RIGHT
.mr-0 {
  margin-right: 0px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.hide {
  display: none;
}

// PLACE-HOLDER-SCSS
::placeholder {
  color: #ccc !important;
  font-size: 13px !important;
}

button,
.mat-checkbox,
.mat-expansion-panel-header,
.mat-expansion-panel-content,
.mat-tab-group,
.mat-radio-button,
.mat-button,
.mat-tab-label,
.mat-tab-link,
table,
.mat-option,
.mat-form-field,
.mat-select,
.mat-button-toggle,
.mat-table,
.mat-input-element,
.mat-cell,
.mat-expansion-panel-header-title,
.mat-stepper-vertical,
.mat-stepper-horizontal {
  font-family: $font-family !important;
}


//Poppins FONT
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');


$font-family: 'Poppins', sans-serif;
$font-family-regular: 'Poppins', sans-serif;
$font-family-medium: 'Poppins', sans-serif;
$font-family-bold: 'Poppins', sans-serif;



$white:#ffffff;
$black:#0e1f29;
$light-blue: #036dd6;
$blue: #0484fe;
$dark-yellow:#DBA100;
$light-yellow:#feb403;
$red:red;
$outline: #D6E0DC;
$orange: #FFA500;
$grey: #ccc;
$whisper: #EEEEEE;
$whisper-dark: #FAFAFA;
$grey-light: #efefef;
$grey-dark: #888888;
$primary-dark-light:#fff6dd;

$base-yellow:#FE9014;
$base-yellow-light:#FFEDD8;

$base-red: #FA5D50;
$base-red-light: #FEE4E2;

$base-green: #2FA84F;
$base-green-light: #DCF7E3;

$base-blue: #367BF5 ;
$base-blue-light: #E6EFFF;


// BODY-COLOR
$body-bg:#fff;


// PRIMARY-COLOR
$primary:#d94132 ;
$secondary:#008332;
$primary-text: #21262c;





.master_router_outlet {
  min-height: auto;
}

.wrapper {
  width: 90%;
  margin: 0 auto;
}

.sec-padding {
  padding: 25px 0 0 0;
}

.out_wrapper {
  width: 95%;
  margin: 0 auto;
}

.custom_heading {
  // margin: 0px 0 -9px 16px;

  .title {
    font-size: 31px;
    font-weight: 500;
    text-align: start;
    letter-spacing: 0;
    margin: 0px -17px 16px;
    padding-left: 32px;
    color: #1a9c4d;


    .primary {
      color: $primary;
    }
  }

  h2 {
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0;
    margin: 0;
    padding: 0;
    color: $primary-text;
  }

  h5 {
    text-align: center;
    color: #1B75BB;
    margin: 10px 0;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
  }

  .primary_color {
    color: $primary;
    margin: 15px auto 0 auto;
    font-size: 24px;
    letter-spacing: 0;
  }

  p {
    color: #888888;
    font-size: 15px;
    line-height: 39px;
    /* margin: 15px auto; */
    width: 100%;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0;
  }
}

.global-btn {
  .mat-button-wrapper {
    font-family: $font-family;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    letter-spacing: 0.5px;
    color: $primary-text;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 20px;
      position: relative;
      top: 0;
      margin-right: 5px;
    }
  }

  img {
    margin-right: 5px;
  }
}

// Scroll-Top
.scroll-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background: #00843E;
  width: 30px;
  height: 30px;
  opacity: 0;
  text-decoration: none;
  border-radius: 24px;
  display: block;
  cursor: pointer;
  -webkit-transition: .2s ease-in-out;
  transition: all 0.5s ease-in-out;
  z-index: 999;

  &.scroll-top-in {
    opacity: 1 !important;
    transition: all .2s ease;
  }

  i {
    color: $white;
    margin: 0;
    line-height: 30px;
    display: block;
    text-align: center;
    font-size: 26px;
    font-weight: 400;
  }

  &:hover {
    background: $primary;

    i {
      color: $white;
    }
  }
}

// Sticky
.sticky {
  position: fixed;
  top: 0;
  animation: slide-down .7s;
  opacity: 1;
  width: 100%;
  z-index: 4;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  // box-shadow: 0 2px 8px 0 #0A597B;
  transition: all .7s ease-in-out;
  background: $white !important;
  z-index: 9999;
  opacity: 1;
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.141));

  .logo-box {

    // top: 0 !important;
    // text-align: center;
    img {
      width: 100px !important;
      margin: 0px 0 0px 0 !important;
      padding: 5px;
    }
  }

  .header-right-navbar {
    padding: 0px 30px 0 0 !important;
    height: 80px;
    line-height: 80px;

    li {
      margin-top: 0 !important;

      // font-weight: 600 !important;
      a {
        color: #051722 !important;

        &.active {
          color: $primary !important;
        }
      }
    }

    .service_anchor {
      img {
        filter: brightness(1) invert(0) !important;
      }
    }
  }
}

// SCOAL-ICONS-FIXED
.float_sm {
  .fl-fl {
    text-transform: uppercase;
    letter-spacing: 3px;
    position: fixed;
    right: -140px;
    z-index: 1000;
    transition: all 0.25s ease;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 0 0px 0;
    width: 180px;

    &:hover {
      img {
        opacity: 1;
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
      }
    }

    img {
      width: 28px;
      position: relative;
      left: 2px;
    }

    &.float-fb {
      top: 400px;

      &:hover {
        .social-icon {
          background: #3b5998 !important;
        }
      }
    }

    &.float-in {
      top: 445px;

      &:hover {
        .social-icon {
          background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
        }
      }
    }

    &.float-tw {
      top: 490px;

      &:hover {
        .social-icon {
          background: #00acee !important;
        }
      }
    }

    &.float-yt {
      top: 365px;
    }

    &.float-ln {
      top: 420px;
    }

    &:hover {
      right: 0;
    }

    .social-icon {
      font-size: 19px;
      color: #fff;
      padding: 0px 6px 0 6px;
      font-weight: 500;
      width: 40px;
      margin-left: 0;
      border-right: 1px solid #fff;
      margin-right: 0px;
      height: 40px;
      line-height: 56px;
      text-align: center;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
      background-color: #fff !important;
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
      filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, .141));

      i {
        // font-size: 18px;
        // opacity: 1;
        // filter: brightness(0) invert(1);
        // text-align: center;
        color: #4f5051;
      }
    }

    span {
      color: #fff;
      text-decoration: none;
      text-align: center;
      display: inline-block;
      font-size: 14px;
      z-index: 99999;
      font-weight: 500;
      position: relative;
      bottom: 0;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 0 5px 0 5px;
      min-width: 130px;
      height: 40px;
      line-height: 40px;
    }
  }
}

.fb-bg {
  span {
    background: #3b5998 !important;
  }
}

.in-bg {
  span {
    // background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%) !important;
    background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)
  }

  // background: #d6249f;
}

.tw-bg {
  span {
    background: #00acee !important;
  }
}

.yt-bg {
  span {
    background: #FF0000 !important;
  }
}

.ln-bg {
  span {
    background: #0e76a8 !important;
  }
}

// Banner-Sec
// .banner-sec {
//   position: relative;
//   width: 100%;
//   height: 100%;

//   // margin-bottom: 50px;
//   .swiper-container {
//     width: 100%;

//     .swiper-wrapper {
//       .swiper-slide {
//         width: 100% !important;
//         position: relative;

//         .banner_overlay {
//           content: '';
//           background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 100%);
//           position: absolute;
//           top: -5px;
//           left: 0;
//           right: 0;
//           bottom: 0;
//           width: 100%;
//           height: 100%;
//           z-index: 1;
//         }
//       }
//     }
//   }

//   .swiper-pagination {
//     bottom: 15px;
//     z-index: 999;

//     // cursor: pointer;
//     .swiper-pagination-bullet {
//       width: 12px;
//       height: 12px;
//       position: relative;
//       transition: all 0.5s ease-in-out;
//       background-color: #fff !important;
//       z-index: 9;
//       opacity: 1;
//       border-radius: 24px;
//       margin: 0 10px;
//       border: 1px solid #fff !important;

//       &.swiper-pagination-bullet-active {
//         width: 30px;
//     border-radius: 19px;
//     background: #E97135 !important;
//     opacity: 1;
//     border-color: #E97135 !important;
// }
//       }
//     }
//   }

//   .banner_container {
//     position: absolute;
//     left: 0;
//     right: 0;
//     bottom: 25%;
//     height: 100%;
//     display: flex;
//     justify-content: flex-start;
//     align-items: flex-end;
//     z-index: 9;

//     .banner-content {
//       width: 80%;

//       h1 {
//         font-size: 34px;
//         font-weight: 700;
//         color: $primary;
//         margin: 0 0 0px 0px;
//         line-height: 54px;
//         padding: 0 0px 5px 0;
//         text-transform: uppercase;
//         color: $white;
//       }
//     }
//   }

// .banner_flower {
//   margin: 0px 0;

//   img {
//     width: 100%;
//     height: 100%;
//   }
// }

.latest_news_sec {
  // margin: 50px 0 0 0;
  background: #d94132;
  height: 50px;

  .new_flex {
    display: flex;
    width: 100%;
    background-color: #F6F6F6;
    height: 51px;
    min-height: 51px;

    .title {
      width: 15%;
      height: 50px;
      line-height: 50px;
      // clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 48%, 0% 0%);
      background-color: green;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      color: #fff;
      padding: 0 0 0 10px;
    }

    .marquee_news {
      width: 100%;
      height: 50px;
      line-height: 50px;

      marquee {
        color: $white;
        background: #d94132;

        span {
          border-left: 4px #c02d1d solid;
          height: 1px;
          padding: 6px 14px 9px 16px;
          margin-left: 20px;
        }
      }
    }
  }
}

// Banner-Sec
.home_banner {
  position: relative;
  width: 100%;
  // height: 100%;
  overflow: hidden;

  .swiper-container {
    width: 100%;
    // height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    // display: flex;
    align-items: center;

    .overlay {
      background: rgb(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      // border-radius: 50%;
    }
  }

  .banner_img {
    position: relative;

    .banner_img {
      width: 100%;
      height: 500px;
      object-fit: cover;
    }

    .curve {
      width: 100%;
      position: absolute;
      left: 0px;
      bottom: 0px;
    }
  }

  .banner_container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;

    .banner_content {
      width: 100%;

      h1 {
        font-size: 32px;
        text-transform: capitalize;
        margin: 0;
        padding: 0;
        width: 100%;
        color: #ffffff;
        line-height: 50px;
        font-style: normal;
        font-weight: 400;
        text-shadow: none;
        letter-spacing: 0px;
        text-transform: none;

        span {
          font-size: 40px;
          font-weight: bolder;
          margin: 10px;

        }
      }
    }

  }

  .swiper-pagination {
    bottom: 10px;

    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      display: inline-block;
      border-radius: 100%;
      background: transparent;
      border: 1px solid $white;
      opacity: .8;
      border: 2px solid #fff !important;
      display: none;

      &.swiper-pagination-bullet-active {
        background: $primary !important;
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    display: none !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: #fff;
    background-repeat: no-repeat;
    height: 60px;
    line-height: 20px;
    background-size: 40% 40% !important;
    background-position: center;
    transition: all .3s ease-in-out 0s;
    box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, .3);
  }

  .swiper-button-prev {
    &:hover {
      background-color: $primary;
      background-repeat: no-repeat;
      background-size: 40% 40% !important;
    }
  }

  .swiper-button-next {
    &:hover {
      background-color: #D6E0DC;
      background-repeat: no-repeat;
      background-size: 40% 40% !important;
    }
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    // background-image: url('../../images/icons/slider-left-arrow.svg') !important;
    background-image: url('/assets/images/icons/slider-left-arrow.svg');
    left: 0px;
    right: auto;
    outline: 0 !important;
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    // background-image: url('../../images/icons/slider-right-arrow.svg') !important;
    background-image: url('/assets/images/icons/slider-right-arrow.svg');
    right: 0px;
    left: auto;
    outline: 0 !important;
  }
}

// Important  Document Pins
.document_pins_sec {
  position: relative;

  // height: 800px;
  .elevation_left {
    content: '';
    background-image: url('/assets/images/elevation_top.png');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .elevation_center {
    content: '';
    background-image: url('/assets/images/bg_element_6.png');
    position: absolute;
    /* top: 0; */
    right: 10%;
    bottom: 15%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: right;
  }

  .document_pins_row {
    .document_pins_leftcolumn {
      .custom_heading {
        margin: 50px 0 0px 0;

        .title {
          // background-image: url('/assets/images/important_document_elevation.png');
          background-repeat: no-repeat;
          background-position: center;
          height: 100%;
          line-height: 70px;
          width: 100%;
          overflow: visible;
          // padding-bottom: 10px;
        }
      }

      .document_box_flex {
        width: 100%;
        display: flex;
        height: 100%;
        margin: 20px 0 0px 0;

        .document_box {
          width: 31%;
          margin-right: 25px;
          border-radius: 8px;
          height: 100%;
          background: #fff;
          box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);

          .document_img {
            width: 100%;
            max-height: 100%;
            overflow: hidden;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }

            .news_type {
              height: 32px;
              line-height: 32px;
              padding: 0 15px;
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              position: absolute;
              top: 0;
              color: #fff;

              &.bg_one {
                background: #ef9d00;
              }

              &.bg_two {
                background: #05D000;
              }

              &.bg_three {
                background: #7E00D7;
              }
            }
          }

          .document_content {
            padding: 10px 10px 15px 10px;

            h3 {
              font-size: 20px;
              font-weight: 550;
              letter-spacing: 0;
              margin: 0 0 10px 0;
              line-height: 23px;
              text-transform: capitalize;
              color: #2da45e;

            }

            p {
              font-size: 13px;
              letter-spacing: 0;
              line-height: 22px;
              font-weight: 400;
              color: #21262c;
              min-height: auto;
              overflow: hidden;
              margin-bottom: 20px;
            }
          }

          .button_box {
            background: whitesmoke;
            padding-top: 20px;

            .read-btn {
              min-width: 150px;
              height: 34px;
              line-height: 30px;
              border-radius: 7px;
              background: #2da45e;
              color: #fff;
              transition: all .5s ease-in-out 0s;
              margin-bottom: 20px;

              // &:hover {
              //   background: $primary;
              //   border-color: $primary;

              //   .mat-button-wrapper {
              //     color: #fff;
              //   }
              // }
            }
          }
        }
      }

      .model_village_sec {
        margin: 0px 25px 0 0;

        .custom_heading {
          .title {
            // background-image: url('/assets/images/narmetta_model_village_elevation.png') !important;
            // padding-bottom: 20px;
          }
        }

        .model_village_banner {
          // width: 100%;
          height: 100%;
          overflow: hidden;
          position: relative;
          margin-right: 25px;

          video {
            width: 100%;
            height: 290px;
            object-fit: cover;
            z-index: 15px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 8px;
          }

          .banner_container {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            // width: 70%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            z-index: 9;

            .content {
              width: 60%;
              margin-bottom: 40px;

              h2 {
                font-size: 22px;
                font-weight: 600;
                margin: 0 0 0px 0px;
                line-height: 40px;
                padding: 0 0px 5px 0;
                color: #ffffff;
                letter-spacing: 0;
              }
            }
          }

          .banner_overlay {
            content: "";
            background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.73) 100%);
            position: absolute;
            top: -5px;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            border-radius: 8px;
          }
        }
      }
    }

    .document_pins_rightcolumn {
      .document_latest_sec {
        height: 100%;
        padding: 10px 25px;
        margin: 50px 0 50px 0;

        .inner_title {
          margin-bottom: 20px;

          h2 {
            color: #03933e;
            font-size: 26px;
            font-weight: 550;
            letter-spacing: 0;
            position: relative;

            // &::after {
            //   content: '';
            //   height: 1px;
            //   width: 100%;
            //   background-color: #FF4141;
            //   position: absolute;
            //   bottom: 0;
            //   left: 0;
            // }
          }
        }

        .document_latest_box {
          margin: 0px 0 25px 0;

          .document_latest_img {
            position: relative;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              margin-bottom: -5px;
            }

            .play_btn {
              position: absolute;
              top: 40%;
              left: 0;
              right: 0;
              bottom: 0;
              margin: 0 auto;
              text-align: center;
              width: auto;
              height: auto;
            }
          }

          .document_latest_content {
            padding: 20px 11px 68px 15px;
            background-color: #03933e;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            clip-path: polygon(100% 0%, 100% 100%, 50% 95%, 0% 100%, 0 48%, 0% 0%);

            .document_latest_date {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 15px;

              span {
                font-size: 13px;
                letter-spacing: 0;
                color: $primary_text;

                &.type {
                  color: $primary;
                }
              }
            }

            h4 {
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 0;
              margin: 0 0 10px 0;
            }

            p {
              font-size: 13px;
              letter-spacing: 0;
              line-height: 22px;
              font-weight: 400;
              color: #21262c;
              overflow: hidden;
            }

            .helpline_list {
              li {
                margin-bottom: 15px;
                border-bottom: 1px #90ee90 dashed;


                h5 {
                  color: $white;
                  font-size: 16px;
                  font-weight: 600;
                  margin-bottom: 3px;
                }

                p {
                  color: $white;
                  font-weight: 500;
                  font-size: 15px;
                }
              }

              li.lines {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}

.initiatives_top {
  content: '';
  background-image: url('/assets/images/initiatives_top.png');
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
  background-position: top;
}

.initiatives_left {
  content: '';
  background-image: url('/assets/images/elevation_top.png');
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.initiatives_right {
  content: '';
  background-image: url('/assets/images/progressive_initiatives_flower.png');
  position: absolute;
  bottom: 50%;
  right: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
  background-position: right;
}

// Progressive Initiatives
.progressive_initiatives_sec {
  width: 100%;
  height: 100%;
  margin: 70px 0 0 0;
  position: relative;

  .initiatives_top {
    content: '';
    background-image: url('/assets/images/initiatives_top.png');
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: top;
  }

  .initiatives_left {
    content: '';
    background-image: url('/assets/images/elevation_top.png');
    position: absolute;
    top: 45%;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .initiatives_right {
    content: '';
    background-image: url('/assets/images/progressive_initiatives_flower.png');
    position: absolute;
    bottom: 50%;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: right;
  }

  .initiatives_banner {
    padding-top: 100px;

    .initiatives_heading_banner {
      width: 80%;
      height: 100%;
      overflow: hidden;
      position: relative;
      margin: 0 auto;
      border-radius: 12px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 12px;
      }

      .banner_container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        // width: 70%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        z-index: 9;

        .content {
          width: 100%;
          margin-bottom: 0px;

          h2 {
            font-size: 24px;
            font-weight: 600;
            margin: 0 0 0px 0px;
            line-height: 40px;
            padding: 0 0px 5px 0;
            color: #ffffff;
            letter-spacing: 0;
            text-align: center;
          }
        }
      }

      .banner_overlay {
        content: "";
        background: rgba(43, 35, 35, 0.23);
        position: absolute;
        top: -6px;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 12px;
      }
    }
  }

  .initiatives_box_row {
    margin: 60px 0 50px 0;

    .swiper-container {
      width: 100%;

      .swiper-wrapper {
        .swiper-slide {
          position: relative;
          padding-bottom: 25px;

          .initiatives_box {
            margin-right: 25px;
            border-radius: 8px;
            background: #fff;
            box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
            width: 100%;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }

            .initiatives_content {
              padding: 10px 10px 15px 10px;

              h3 {
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 0;
                margin: 0 0 10px 0;
                text-transform: capitalize;
              }

              p {
                font-size: 14px;
                letter-spacing: 0;
                line-height: 22px;
                font-weight: 400;
                color: #21262c;
                min-height: auto;
                overflow: hidden;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: #fff;
    background-repeat: no-repeat;
    height: 60px;
    line-height: 20px;
    background-size: 40% 40% !important;
    background-position: 50%;
    transition: all .3s ease-in-out 0s;
    box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, .3);
  }

  .swiper-button-next {
    right: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    &::after {
      font-size: 18px;
      font-weight: 800;
    }

    img {
      width: 15px;
    }
  }

  .swiper-button-prev {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    left: 0;

    &::after {
      font-size: 18px;
      font-weight: 800;
    }

    img {
      width: 15px;
    }
  }
}

// Village Virtual Office
.virtual_office_sec {
  position: relative;

  .custom_heading {
    overflow: hidden;

    h2 {
      position: relative;
      text-align: left;

      &::after {
        content: '';
        background-image: url('/assets/images/village_vision_narmetta_divider.png');
        width: 100%;
        height: 100%;
        position: absolute;
        background-repeat: no-repeat;
        top: 0px;
        object-fit: contain;
        background-position: left;
        // background-size: contain;
      }

      span {
        padding-right: 20px;
        position: relative;
        top: 2px;
      }
    }
  }

  .virtual_right {
    content: '';
    background-image: url('/assets/images/virtual_right_off.png');
    position: absolute;
    bottom: 50%;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: right;
  }

  .virtual_left {
    content: '';
    background-image: url('/assets/images/virtual_bottom_left.png');
    position: absolute;
    top: 55%;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .virtual_office_row {
    margin: 70px 0 50px 0;

    .virtual_office_column {
      .virtual_box {
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);

        .virtual_img {
          width: 100%;
          max-height: 100%;
          overflow: hidden;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .virtual_content {
          padding: 10px 10px 15px 10px;

          h3 {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            margin: 0 0 10px 0;
          }

          p {
            font-size: 13px;
            letter-spacing: 0;
            line-height: 22px;
            font-weight: 400;
            color: #21262c;
            min-height: auto;
            overflow: hidden;
            margin-bottom: 20px;
          }

          .read-btn {
            min-width: 125px;
            height: 30px;
            line-height: 25px;
            border-radius: 27px;
            background: $primary;
            border: 2px solid #dddfe1;
            position: relative;
            transition: all .5s ease-in-out 0s;
            border-color: $primary;

            .mat-button-wrapper {
              color: $white;
            }

            &:hover {
              background: $white;
              border-color: $white;

              .mat-button-wrapper {
                color: $primary;
              }
            }
          }
        }
      }
    }
  }

  .virtual_office_bottom {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

// Village Vision Narmetta
.village_vision_sec {
  position: relative;
  margin: 50px 0 0 0;

  .vision_right {
    content: '';
    background-image: url('/assets/images/virtual_right_off.png');
    position: absolute;
    bottom: 50%;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: right;
  }

  .vision_left {
    content: '';
    background-image: url('/assets/images/virtual_bottom_left.png');
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: left;
  }

  .vision_bottom_right {
    content: '';
    background-image: url('/assets/images/vision_right.png');
    position: absolute;
    // top: 10%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: right;
  }

  .custom_heading {
    &.bg {
      overflow: hidden;

      h2 {
        position: relative;
        text-align: left;

        &::after {
          content: '';
          background-image: url('/assets/images/village_vision_narmetta_divider.png');
          width: 100%;
          height: 100%;
          position: absolute;
          background-repeat: no-repeat;
          top: 0px;
          object-fit: contain;
          background-position: left;
          // background-size: contain;
        }

        span {
          padding-right: 20px;
          position: relative;
          top: 2px;
        }
      }
    }
  }

  .village_vision_row {
    margin: 50px 0;

    .village_vision_column {
      background: #fef7e9;

      .Vision_banner {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        margin: 0 auto;
        // border-radius: 6px;

        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }



        .content {
          width: 100%;
          height: 277px;
          margin-top: 16px;
          padding-left: 36px;

          h2 {
            font-size: 22px;
            font-weight: 600;
            margin: 0 0 0px 0px;
            line-height: 40px;
            padding: 0 0px 5px 0;
            color: #df5343;
            letter-spacing: 0;
            text-align: left;
          }

          p {
            margin: 2px 71px 18px 0px;
          }

          .read-btn {
            background: #d94132;
            color: white;
          }
        }


        .banner_overlay {
          content: "";
          // background: rgba(43, 35, 35, 0.23);
          position: absolute;
          top: -5px;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          border-radius: 15px;
          background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 100%);
        }
      }

      &.document_column {
        .custom_heading {
          .title {
            font-size: 22px;
          }
        }

        .document_list {
          ul {
            padding-right: 10px;

            li {
              margin: 15px 5px;

              .media {
                align-items: center;

                .media-left {
                  margin-right: 0;

                  img {
                    width: 55px;
                  }
                }

                .media-body {
                  padding-right: 10px;

                  p {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                    color: $primary_text;
                  }
                }
              }
            }
          }
        }
      }

      &.public_places_column {
        .public_places_box {
          border: 1px solid #eee;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;

          .public_places_head {
            height: 60px;
            background: #222;
            line-height: 60px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            h4 {
              color: $primary;
              font-size: 18px;
              font-weight: 700;
              text-align: center;
              letter-spacing: 0;
              margin: 0;
              padding: 0;
            }
          }

          .public_places_body {
            .public_places_list {
              padding: 10px;

              li {
                // margin: 15px 0;
                // height: 110px;
                // border-radius: 0px 8px 8px 0px;
                // background: #f6f6f6;
                // padding-right: 10px;
                margin: 15px 0;
                height: 86px;
                border-radius: 0px 8px 8px 0px;
                background: #f6f6f6;
                padding-right: 10px;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;

                .media {
                  align-items: center;

                  .media-left {
                    overflow: hidden;
                    margin-right: 20px;

                    img {
                      width: 115px;
                      // height: 90px;
                      // object-fit: contain;
                      border-top-left-radius: 8px;
                      border-bottom-left-radius: 8px;
                      height: 100%;
                      min-height: 85px;
                    }
                  }

                  .media-body {

                    // height: 86px;
                    h4 {
                      font-size: 16px;
                      font-weight: 600;
                      color: #293C4D;
                      margin: 0;
                    }

                    p {
                      font-size: 13px;
                      font-weight: 500;
                      line-height: 20px;
                      color: $primary_text;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                    }

                    .read-btn {
                      min-width: 90px;
                      height: 27px;
                      line-height: 22px;
                      border-radius: 27px;
                      background: $primary;
                      border: 2px solid #dddfe1;
                      position: relative;
                      transition: all 0.5s ease-in-out 0s;
                      border-color: #E97135;
                      color: $white;
                      margin-top: 15px;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Weekly Newsletter
.weekly_newsletter {

  // padding-top: 150px;
  .newsletter_banner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    border-radius: 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 12px;
    }

    .banner_container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      // width: 70%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 9;

      .content {
        width: 100%;
        margin-bottom: 40px;

        h2 {
          font-size: 26px;
          font-weight: 600;
          margin: 0 0 0px 0px;
          line-height: 40px;
          padding: 0 0px 5px 0;
          color: #ffffff;
          letter-spacing: 0;
          text-align: center;
        }

        p {
          font-size: 15px;
          text-align: center;
          color: #fff;
          left: 0.5px;
        }
      }
    }

    .banner_overlay {
      content: "";
      background: linear-gradient(rgba(0, 0, 0, 0.39) 0%, rgba(0, 0, 0, 0.86) 100%);
      opacity: 0.69;
      position: absolute;
      top: -5px;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: 12px;
    }
  }

  .footer-notify-input {
    display: flex;
    width: 40%;
    justify-content: center;
    margin: 30px auto 0 auto;
    font-family: $font-family;

    .custom-form-group {
      position: relative;
      width: 100%;

      .custom-form-control {
        position: relative;

        .mat-form-field {
          width: 100%;

          .mat-form-field-wrapper {

            // padding-bottom: 0px !important;
            // margin: 0px !important;
            .mat-form-field-flex {
              .mat-form-field-outline {
                background: transparent;
                border-radius: 25px !important;
                box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
                outline: 0px !important;
                background: #FFFFFF !important;
                border-top-left-radius: 25px !important;
                border-bottom-left-radius: 25px !important;
                border-top-right-radius: 25px !important;
                border-bottom-right-radius: 25px !important;

                .mat-form-field-outline-start {
                  min-width: 0;
                  border-left-style: none;
                }

                .mat-form-field-outline-end {
                  border-radius: 40px !important;
                  border-left-style: solid;
                }
              }

              .mat-form-field-infix {
                padding: 0.8em 0 1.1em 0 !important;
              }
            }
          }
        }
      }
    }

    .icon-btn {
      height: 40px;
      margin: 8px 3px 3px 3px;
      position: absolute;
      right: 5px;
      top: 0;
      bottom: 0;
      background: $primary;
      border: 1px solid $primary;
      padding-left: 0px;
      padding-right: 0px;
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      min-width: 150px;

      .mat-button-wrapper {
        color: #fff;
        letter-spacing: 0.5px;
        font-size: 16px;
      }
    }

    .mat-form-field-outline-thick {
      color: transparent !important;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #838490;
      opacity: 1;
      /* Firefox */
      font-weight: 400;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #838490;
      font-weight: 400;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #838490;
      font-weight: 400;
    }

    .mat-error {
      color: #fff;
    }
  }
}

// Latest Photo / Event Galleries
.latest_photo_sec {
  margin: 50px 0 0px 0;
  position: relative;
  padding-bottom: 50px;

  .photo_overlay_center {
    content: "";
    background-image: url('/assets/images/bg_element_5.png');
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: bottom;
  }

  .photo_overlay_right {
    content: '';
    background-image: url('/assets/images/virtual_right.png');
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: right;
  }

  .latest_photo_row {
    .latest_photo_column {
      &.left {
        .custom_heading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 0 30px 0;

          .view-btn {
            .mat-button-wrapper {
              font-size: 18px;
              font-weight: 600;
              color: #333333;
            }
          }
        }

        .galleries_banner {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .galleries_box {
            width: 100%;
            height: 100%;
            margin-right: 10px;

            .gallery_img {
              width: 100%;
              height: 100%;
              border: 2px solid #eee;
              padding: 1px;
              border-radius: 8px;
              margin-bottom: 10px;

              img {
                width: 100%;
                height: 100%;
              }

              video {
                width: 100%;
                height: 100%;
                border-radius: 8px;
              }
            }

            .gallery_content {
              h4 {
                font-size: 18px;
                font-weight: 600;
                color: #293C4D;
                text-align: center;
                margin: 25px 0 10px 0;
              }

              p {
                text-align: center;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                font-size: 13px;
                font-weight: 500;
                line-height: 22px;
                color: $primary_text;
                padding: 0 50px;
              }
            }
          }
        }

        .gallery_list {
          ul {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            margin: 25px 0 0 0;

            li {
              width: 140px;
              height: 120px;
              border-radius: 6px;
              margin-right: 10px;

              // img {
              //   width: 140px;
              //   height: 120px;
              //   border-radius: 6px;
              // }
              .gallery_bg_img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
                background-position: top;
                background-size: cover;
                background-repeat: no-repeat;
                border: 1px solid #eee;

                a {
                  width: 140px;
                  height: 120px;
                  border-radius: 6px;

                  .card_img {
                    width: 140px;
                    height: 120px;
                    border-radius: 6px;

                    h5 {
                      font-size: 16px;
                      color: #fff;
                      padding: 5px;
                      text-align: center;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin: 0;
                      line-height: 24px;
                      text-transform: capitalize;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.right {
        .feed-iframe-box {
          width: 340px;
          height: 100%;
          overflow: hidden;
          text-align: right;
          border: 1px solid #cccccca3;
          border-radius: 4px;
          background: #eee;
          margin: 0 auto;

          .feed-head {
            text-align: left;
            border: 1px solid #eee;
            border-bottom: 1px solid #ccc;
            background: #eee;
            height: 38px;
            line-height: 35px;
            padding: 0 0 0 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            div {
              display: flex;
            }

            img {
              width: 22px;
            }

            span {
              font-weight: 500;
              padding: 0 0 0 10px;
              text-transform: capitalize;
              font-size: 15px;
              color: #000;
              letter-spacing: 0.5px;
            }

            .follow_btn {
              width: 85px;
              height: 28px;
              line-height: 28px;
              border-radius: 22px;
              background: #1976d2;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;

              .mat-button-wrapper {
                color: #fff !important;
                text-align: center;
                line-height: 28px;
              }
            }
          }

          iframe {
            // margin: 10px;
            // border: 1px solid $whisper-dark;
            // padding: 10px 5px 10px 5px;
            // border-radius: 4px;
            // width: 350px;
            // height: 450px;
            // background: $whisper-dark;
            margin: 0;
            border: 1px solid $whisper-dark;
            padding: 0px 0 0px 0;
            border-radius: 4px;
            // width: 365px;
            height: 450px;
            background: $whisper-dark;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

// E - governance
.e_governance_bg {
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.e_governance_sec {

  // background: #F6F6F6;
  .custom_heading {

    .title {
      text-align: center !important;
      margin: 0 auto;
      padding: 0;
    }
  }

  .gov_heading {
    margin-top: -30px;
    margin-bottom: 50px;

    h2 {
      text-align: center;

      span {
        border-radius: 52.5px;
        background: #e97135;
        box-shadow: 0px 13px 22px rgba(65, 65, 65, 0.15);
        padding: 8px 60px;
        font-weight: bold;
        font-size: 22px;
        text-align: left;
        color: #fff;
      }
    }
  }

  .e_governance_row {
    .e_governance_column {
      .links-section {
        padding: 25px 0 0 0px;
        padding-bottom: 0;
        position: relative;

        &:before {
          // content: '';
          // position: absolute;
          // bottom: 0;
          // right: 0;
          // left: 0;
          // height: 45px;
          // background-image: url('../assets/images/newsletter.png');
          // background-size: contain;
          // background-repeat: repeat !important;
          // background-position: center;
        }

        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          position: relative;

          .line_overlay {
            content: "";
            background-image: url('/assets/images/line.png');
            position: absolute;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
          }

          li {
            display: inline-flex;
            align-items: center;
            width: 42%;
            border-left: 0px;
            border-radius: 35px;
            cursor: pointer;
            padding-left: 50px;
            position: relative;
            margin-bottom: 35px;
            //margin-left: 30px;
            background: rgba(0, 80, 13, 0.06);
            height: 60px;

            //  &:nth-child(2n) {
            //   margin-left: 35px;
            //   // border-left:1px solid #FFB60C !important;
            // }
            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
              background: #fff;
              border-radius: 50%;
              position: absolute;
              left: 15px;
            }

            p {
              color: #00843E;
              font-size: 18px;
              line-height: 20px;
              font-weight: 500;
              letter-spacing: 0;
              margin-bottom: 0px;
              padding-left: 20px;
            }
          }
        }

        .bottom_link {
          margin: 25px 0 0 0;

          li {
            display: inline-flex;
            align-items: center;
            width: 25%;
            border-left: 0px;
            border-radius: 35px;
            cursor: pointer;
            padding-left: 50px;
            position: relative;
            margin-bottom: 35px;
            margin-left: 30px;
            background: rgba(0, 80, 13, 0.06);
            height: 60px;

            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
              background: #fff;
              border-radius: 50%;
              position: absolute;
              left: 15px;
            }

            p {
              color: #00843E;
              font-size: 16px;
              line-height: 20px;
              font-weight: 500;
              letter-spacing: 0;
              margin-bottom: 0px;
              padding-left: 20px;
            }
          }
        }
      }
    }
  }
}

.coming_soon_sec {
  .coming_soon_row {
    .coming_soon_column {
      .coming_soon_left {
        // min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .coming_soon_flex {
          width: 100%;
          text-align: center;
          overflow: hidden;
        }

        .coming-soon-title {
          // position: relative;
          margin-top: 10vh;
          margin-bottom: 5vh;

          h3 {
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            padding-left: 30px;
            padding-right: 30px;
            position: relative;
            width: 100%;
            z-index: 999999;
            overflow: hidden;
            text-transform: uppercase;
            width: 70%;
            margin: 0 auto;

            span {
              background: #fff;
              padding: 0 50px;
              color: $primary;
              font-size: 40px;
            }

            &::after {
              content: "";
              height: 2px;
              width: 100%;
              position: absolute;
              top: 53%;
              background: $primary;
              left: 0;
              right: 0;
              z-index: -1;
            }
          }
        }

        // .head {
        //   h2 {
        //     color: $primary;
        //     font-weight: 700;
        //     width: 90%;
        //     font-size: 50px;
        //     text-transform: uppercase;
        //     text-align: center;
        //     margin: 50px 0 0 0;
        //     letter-spacing: 0;
        //     position: relative;
        //     &::before {
        //       position: absolute;
        //       left: 0;
        //       top: 50%;
        //       -webkit-transform: translateY(-50%);
        //       -ms-transform: translateY(-50%);
        //       transform: translateY(-50%);
        //       width: 100%;
        //       z-index: -1;
        //       height: 3px;
        //       background-color: #04050a;
        //       content: '';
        //     }
        //   }
        // }
      }

      .coming_soon_right {
        overflow: hidden;
        // padding: 90px;
        // min-height: 100vh;
        // background: #6463f1;
        background: #F6F6F6;
        // background: linear-gradient(180deg, #6463f1 0%, #6b6af5 100%);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
          // height: 100vh;
        }
      }
    }
  }
}

//inner page css
.inner_sec {
  img {
    width: 100%;
    height: 300px;
  }
}

.adrash-gram {
  background-size: cover;
  background-image: linear-gradient(rgb(34, 139, 34, 0.7), rgb(34, 139, 34, 0.7)), url("../assets/images/grama.jpg");
  position: relative;
  padding: 40px 0px;

  .custom_heading {
    margin: 15px;

    h2 {
      font-size: 22px;
      color: $white;
    }

    p {
      color: $white;
      font-weight: 400;
      font-size: 12px;
    }
  }

  .steps {
    margin: 15px;

    color: #fff;
    text-align: left;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;

    .step-img {
      margin-right: 15px;
      background-color: #1a9c4d;
      width: 82px;
      height: 82px;
      clip-path: polygon(49% 0, 100% 25%, 100% 72%, 51% 98%, 0 75%, 0 24%);

      img {
        width: 51px;
        height: 51px;
        position: relative;
        left: 13px;
        top: 12px;
        object-fit: contain;
        align-items: center;
      }
    }

    .step-conent {
      flex: 1;
      display: table-cell;
      vertical-align: top;
    }

    h6 {
      font-size: 15px;
      margin: 0;
      color: #fff;
    }

    p {
      font-size: 12px;
      margin: 0;
      color: #fff;
    }
  }

  .read-btn {
    min-width: 150px;
    height: 34px;
    line-height: 30px;
    border-radius: 7px;
    background: #da3927;
    color: #fff;
    transition: all .5s ease-in-out 0s;
    margin: 18px 0px 38px 32px;
  }
}

.innerpage_sec {
  padding: 0px 0 10px 0px;
  position: relative;
  // min-height: 100vh;
  background-image: url(../assets/images/background.png);
  // clip-path: polygon(75% 0%, 100% 0%, 75% 50%, 0% 100%, 0 48%, 0% 0%);


  .custom_heading {
    // margin: 50px 0 0px 0;

    .title {
      // background-image: url('/assets/images/important_document_elevation.png');
      background-repeat: no-repeat;
      background-position: center;
      height: 100%;
      line-height: 100px;
      width: 100%;
      overflow: visible;

    }
  }

  .elevation_left {
    content: '';
    background-image: url('/assets/images/elevation_top.png');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .elevation_center {
    content: '';
    background-image: url('/assets/images/bg_element_6.png');
    position: absolute;
    /* top: 0; */
    right: 10%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: right;
  }

  .photo_overlay_right {
    content: '';
    background-image: url('/assets/images/virtual_right.png');
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: right;
  }

  .photo_overlay_center {
    content: "";
    background-image: url('/assets/images/bg_element_5.png');
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: center;
    opacity: 0.3;
  }

  .wrapper {
    .custom-header {
      text-align: center;
      padding-bottom: 25px;

      .title {
        background-image: url(/assets/images/important_document_elevation.png);
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        line-height: 100px;
        width: 100%;
        overflow: visible;
        padding-bottom: 10px;

        .primary {
          color: #E97135;
        }
      }
    }

    .card_box {
      // position: relative;
      // background-image: url('/assets/images/village_exhibition_center.png') !important;;
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      // //  width: 25%;
      // height: 150px;
      // //filter: grayscale(60%);
      // transition: transform 1s, filter 2s ease-in-out !important;
      // //  filter: blur(2px);
      // //transform: scale(1.2);
      // border-radius: 10px;
      // margin: 10px;
      position: relative;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 180px;
      transition: transform 1s, filter 2s ease-in-out !important;
      margin: 10px;

      .card_img {
        //background: rgba(0, 0, 0, .3);
        height: 181px;
        //background: rgb(113,95,95);

        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(113, 95, 95, 0.1) 46%);



        // border-radius: 10px;

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
        }

        h5 {
          color: #FFF;
          font-weight: 500;
          text-transform: capitalize;
          font-size: 22px;
          text-align: center;
          position: relative;
          top: 115px;
          /* background: #ffff; */
          padding-top: 20px;
          //  width: 100%; 
          // height: 37%;
        }
      }

      .card_conent {
        position: relative;

        h5 {

          color: #fff;
          font-weight: 500;
          text-transform: capitalize;
          font-size: 21px;
          text-align: center;
          position: relative;
          bottom: 24px;

        }

      }

      .overlay {
        background: rgb(0, 0, 0, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        // border-radius: 50%;
      }

    }

    .widhun {
      width: 98%;
      clear: both;
      padding: 0px;
      margin: 0 auto;

      h1 {
        font-size: 30px;
        font-weight: 600;
      }

      h2 {
        // padding-bottom: 10px;
        font-weight: 700;
        font-size: 26px;
      }

      h3 {
        font-size: 23px;
        font-weight: 600;
      }

      h4 {
        font-size: 19px;
        font-weight: 600;
      }

      h5 {
        font-size: 16px;
        font-weight: 600;
      }

      h6 {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.2px;
        text-transform: capitalize;
        // width: 80%;
        // text-align: center;
        // margin: 50px 0 0 0;

      }

      p {
        margin-bottom: 14px;
        font-size: 20px;
        line-height: 1.5;
        width: 100%;
        letter-spacing: 0.2px;
      }

      ul {
        padding: 10px;

        // padding-left: 50px;
        li {
          padding: 13px 0px 11px 35px;
          font-size: 18px;
          font-weight: 400;
          position: relative;

          &::before {
            content: "";
            background-image: url('../assets/images/ticks.png');
            position: absolute;
            left: -11px;
            top: 13px;
            background-size: contain;
            width: 26px;
            height: 21px;
            background-repeat: no-repeat;
            font-weight: 600;
            z-index: 0 !important;

          }
        }
      }
    }

    .table-section {
      border: 1px solid #5E1D14 !important;
      border-radius: 12px !important;
      overflow: hidden;
      width: 65%;
      margin: 20px auto;
    }

    .swiper-button-prev {
      background: $white;
      height: 35px;
      width: 35px;
      border-radius: 50%;

      left: 0 !important;
      cursor: pointer;

      img {
        height: 16px;
        // transform: rotate(180deg);
      }
    }

    .swiper-button-next {
      background: $white;
      height: 35px;
      width: 35px;
      border-radius: 50%;

      right: 0 !important;
      cursor: pointer;

      img {
        height: 16px;
      }
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      display: none !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
      background-color: #03933e;
      background-repeat: no-repeat;
      height: 60px;
      line-height: 20px;
      background-size: 40% 40% !important;
      background-position: center;
      // transition: all .3s ease-in-out 0s;
    }

    .swiper-button-prev {
      border-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      img {
        width: 12px;
        position: relative;
        right: 3px;
      }


    }

    .swiper-button-next {
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      img {
        width: 12px;
      }

      // &:hover {
      //   background-color: $primary;
      //   background-repeat: no-repeat;
      //   background-size: 40% 40% !important;
      // }
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 0px !important;
      right: auto;
      top: 150px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 0px !important;
      left: auto;
      top: 150px;
    }
  }



  .govn_box_flex {
    width: 100%;
    display: flex;
    height: 100%;
    margin: 50px 0 0px 0;

    .govn_box {
      width: 100%;
      margin-right: 16px;
      background: #03933e;
      box-shadow: 0px 3px 16px rgb(0 0 0 / 10%);
      clip-path: polygon(100% 0%, 100% 100%, 50% 95%, 0% 100%, 0 48%, 0% 0%);

      .govn_img {
        width: 100%;
        max-height: 100%;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
        }
      }

      .govn_content {
        padding: 10px 10px 15px 10px;

        h3 {
          font-size: 20px;
          font-weight: 550;
          letter-spacing: 0;
          margin: 0px 0px 15px 18px;
          line-height: 23px;
          text-transform: capitalize;
          color: #fff;

        }

        p {
          font-size: 13px;
          letter-spacing: 0;
          line-height: 22px;
          font-weight: 400;
          color: #21262c;
          min-height: auto;
          overflow: hidden;
          margin-bottom: 20px;
        }
      }



      .read-btn {
        min-width: 150px;
        height: 34px;
        line-height: 30px;
        border-radius: 7px;
        background: #da3927;
        color: #fff;
        transition: all .5s ease-in-out 0s;
        margin: 0px 0px 26px 29px;

        // &:hover {
        //   background: $primary;
        //   border-color: $primary;

        //   .mat-button-wrapper {
        //     color: #fff;
        //   }
        // }
      }

    }
  }
}


.innerpage_bottom_sec {
  width: 100%;
  height: 100%;
  margin: 0px 0 0 0;
  position: relative;
  height: 50px;


  .initiatives_top {
    content: '';
    background-image: url('/assets/images/initiatives_top.png');
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: top;
  }
}

table {
  background: #00843E;
  text-align: center;
  border: 0px !important;
  margin: 20px auto !important;
  width: auto;
  min-width: 80%;
  max-width: max-content;
  border-radius: 10px;
  padding: 15px 15px;



  // td {
  //   color: #5E1D14;
  //   font-size: 16px;
  //   font-weight: 600;
  //   border-bottom: 0px !important;
  //   border-top: 0px !important;
  //   padding: 6px 0px;
  // }
  th {
    color: #3D556B;
    font-weight: 700;
    font-size: 18px;
    border-top: 0px;
    letter-spacing: 1.2px;
    border-bottom: 1px solid #5E1D14 !important;
    padding: 9px 0px;
  }

  td {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0;
    border-bottom: 1px solid #eee !important;
    padding: 8px 0px;

    p {
      font-size: 13px !important;
      width: 98% !important;
      margin-bottom: 0px !important;

      .marker {
        color: green !important;
        background: #fff;
        padding: 4px 7px;
        border-radius: 8px;
      }
    }

  }

  thead {
    th {
      color: #3D556B;
      font-weight: 700;
      font-size: 18px;
      border-top: 0px;
      letter-spacing: 1.2px;
      border-bottom: 1px solid #5E1D14 !important;
      padding: 9px 0px;
    }
  }
}

td {
  border-top: 0px !important;
}

.single-video {
  margin: 50px auto;
  width: 96%;

  video {
    //width: 200px;
    height: 220px;
    padding: 4px;
    // border: 1px solid #eee;
    margin: 7px;
    object-fit: cover;
    border-radius: 15px;
    width: 22%;
  }

  img {
    height: 220px;
    padding: 4px;
    // border: 1px solid #eee;
    margin: 3px;
    object-fit: cover;
    border-radius: 15px;
    width: 22%;
    padding: 8px 8px;
  }

}



.swiper-button-next:after,
.swiper-button-prev:after {
  display: none !important;
}

.initiatives_box_row {
  .initiatives_box {
    .initiatives_content {
      .read-btn {
        min-width: 125px;
        height: 30px;
        line-height: 25px;
        border-radius: 27px;
        background: #E97135;
        border: 2px solid #dddfe1;
        position: relative;
        transition: all 0.5s ease-in-out 0s;
        border-color: #E97135;
        color: white;
      }
    }
  }
}

.mat-button-toggle-appearance-standard {
  .mat-button-toggle-label-content {
    padding: 0 12px;
    width: auto;
    line-height: 30px;
  }
}

.li {
  .media {
    .mat-focus-indicator {
      .btn {
        .btn-sm {
          .read-btn {
            .mat-button {
              .mat-button-base {
                color: white;
                background: #E97135;
                ;
                border-radius: 13px;
                padding: 5px 6px;
                border-color: #E97135;
              }
            }
          }
        }
      }
    }
  }
}

.custom-active-slide {
  .card_img {
    // border: 2px solid #03933e;

  }

}


.gall-vid {
  .mat-button-toggle-appearance-standard {
    .mat-button-toggle-label-content {
      color: #fff;
      background: #03933e;

      &:hover {
        background: #d94132;
        color: #fff;
      }
    }

    &.mat-button-toggle-checked {

      .mat-button-toggle-button {
        .mat-button-toggle-label-content {
          background: #d94132;
          color: #fff;
        }
      }
    }
  }
}

.step_villa {
  h1 {
    padding: 15px 15px;
    color: #E97135;
    text-decoration: underline;
  }

  ul {
    padding: 10px;

    // padding-left: 50px;
    li {
      padding: 10px;
      font-size: 14px;
      font-weight: 400;
      position: relative;

      &::before {
        content: '';
        background-image: url('../assets/images/ticks.png');
        position: absolute;
        left: -14px;
        top: 13px;
        background-size: contain;
        width: 27px;
        height: 22px;
        background-repeat: no-repeat;
        //z-index: 99999;
        font-weight: 600;
      }
    }
  }
}




.place_box {
  width: 100%;
  height: 515px;
  background-color: #03933e;
  clip-path: polygon(100% 0%, 100% 100%, 50% 95%, 0% 100%, 0 48%, 0% 0%);

  .read-btn {
    background: #d94132;
    color: white;
  }

  .public_places_head {
    height: 60px;
    line-height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;


    h4 {
      color: $white;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      letter-spacing: 0;
      margin: 0;
      padding: 0;
    }
  }

  .media {
    height: 128px;

    img {
      width: 154px;
      height: 128px;
      object-fit: contain;

    }
  }

  .media-body {
    background: #95d3ae;
    width: 175px;
    position: relative;
    left: 50px;
    height: 95px;

    h1 {
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      position: relative;
      top: 16px;
      color: #1b9e51;
    }

    .read-btn {
      background: #03933e;
      color: white;
      margin: 0 39px;
    }
  }

  .media-bodys {
    background: #95d3ae;
    width: 175px;
    position: absolute;
    right: 83px;
    top: 79px;
    height: 95px;


    h1 {
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      position: relative;
      top: 16px;
      color: #1b9e51;
    }

    .read-btn {
      background: #03933e;
      color: white;
      margin: 0 39px;
    }
  }

  .media-bods {
    background: #95d3ae;
    width: 175px;
    position: absolute;
    right: 83px;
    bottom: 170px;
    height: 95px;

    h1 {
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      position: relative;
      top: 16px;
      color: #1b9e51;
    }

    .read-btn {
      background: #03933e;
      color: white;
      margin: 0 39px;
    }
  }

}



.banner {
  .bann-image {
    position: relative;

    img {
      width: 100%;
      height: 500px;
      object-fit: cover;
    }

    .overlay {
      background: rgb(0, 0, 0, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      // border-radius: 50%;
    }
  }

  .testimonial {
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
    border-top: 3px solid #fff;
    padding: 64px 30px 75px 37px;
    z-index: 1;
    position: absolute;
    top: 21%;
    left: 50%;
    height: 30%;

    .description {
      font-size: 14px;
      color: #fff;
      line-height: 27px;
      position: relative;
    }

    .title {
      font-size: 22px;
      font-weight: 800;
      color: #fff;
      text-transform: capitalize;
      position: absolute;
      top: 29px;
      left: 36px;
    }

    h1 {
      color: white;
      font-weight: 600;
      font-size: 23px;
    }

    .buttons-baner {
      position: relative;
      top: 35px;

      .read-btn {
        min-width: 163px;
        height: 45px;
        line-height: 30px;
        font-weight: 500;
        border-radius: 7px;
        background: #03933e;
        color: #fff;
        transition: all 0.5s ease-in-out 0s;
        margin-bottom: 20px;
        margin-right: 15px;

      }

      .mor-btn {
        min-width: 165px;
        height: 45px;
        line-height: 30px;
        font-weight: 500;
        border-radius: 7px;
        background: #da3927;
        color: #fff;
        transition: all .5s ease-in-out 0s;
        margin-bottom: 20px;
      }
    }
  }

  .testimonial:before,
  .testimonial:after {
    content: "";
    width: 100px;
    /* height: 95px; */
    position: absolute;
    right: 0px;
    bottom: 0px;
    border-bottom: 3px solid white;
    /* left: -26px; */
  }

  .testimonial:before {
    border-bottom: 3px solid white;
    position: absolute;
    top: 0;
    right: 83%;
  }
}

.innerpage {
  padding: 51px 0 10px 0px;
  position: relative;
  background-image: url(../assets/images/background.png);
  // clip-path: polygon(100% 0%, 100% 100%, 100% 100%, 100% 100%, 0 48%, 0% 0%);
  background-color: #fff9ef;

  .custom_heading {
    // margin: 50px 0 0px 0;

    .title {
      // background-image: url('/assets/images/important_document_elevation.png');
      background-repeat: no-repeat;
      background-position: center;
      height: 100%;
      line-height: 100px;
      width: 100%;
      overflow: visible;
      padding-bottom: 10px;
    }
  }

  .elevation_left {
    content: '';
    background-image: url('/assets/images/elevation_top.png');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .elevation_center {
    content: '';
    background-image: url('/assets/images/bg_element_6.png');
    position: absolute;
    /* top: 0; */
    right: 10%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: right;
  }

  .photo_overlay_right {
    content: '';
    background-image: url('/assets/images/virtual_right.png');
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: right;
  }

  .photo_overlay_center {
    content: "";
    background-image: url('/assets/images/bg_element_5.png');
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: center;
    opacity: 0.3;
  }

  .wrapper {
    .custom-header {
      text-align: center;
      padding-bottom: 25px;

      .title {
        background-image: url(/assets/images/important_document_elevation.png);
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        line-height: 100px;
        width: 100%;
        overflow: visible;
        padding-bottom: 10px;

        .primary {
          color: #E97135;
        }
      }
    }

    .card_box {
      // position: relative;
      // // background-image: url('/assets/images/village_exhibition_center.png') !important;;
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      // //  width: 25%;
      // height: 150px;
      // //filter: grayscale(60%);
      // transition: transform 1s, filter 2s ease-in-out !important;
      // //  filter: blur(2px);
      // //transform: scale(1.2);
      // border-radius: 10px;
      // margin: 10px;
      position: relative;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 330px;
      transition: transform 1s, filter 2s ease-in-out !important;
      margin: 10px;

      .card_img {
        //background: rgba(0, 0, 0, .3);
        height: 130px;
        //background: rgb(113,95,95);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(113, 95, 95, 0.5) 66%);
        border-radius: 10px;

        img {
          width: 100%;
          height: 200px;
        }

      }

      .card_conent {
        background: #ffff;
        padding-top: 100px;

        h5 {

          color: #21262c;
          font-weight: 500;
          text-transform: capitalize;
          font-size: 20px;
          text-align: center;
          position: relative;
          bottom: 15px;
        }
      }
    }

    .widhun {
      width: 98%;
      clear: both;
      padding: 20px 0px;
      margin: 0 auto;

      h1 {
        font-size: 30px;
        font-weight: 600;
      }

      h2 {
        // padding-bottom: 10px;
        font-weight: 700;
        font-size: 26px;
      }

      h3 {
        font-size: 23px;
        font-weight: 600;
      }

      h4 {
        font-size: 19px;
        font-weight: 600;
      }

      h5 {
        font-size: 16px;
        font-weight: 600;
      }

      h6 {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.2px;
        text-transform: capitalize;
        // width: 80%;
        // text-align: center;
        // margin: 50px 0 0 0;

      }

      p {
        margin-bottom: 15px;
        font-size: 15px;
        line-height: 26px;
        width: 80%;
        letter-spacing: 0.2px;
      }

      ul {
        padding: 10px;

        // padding-left: 50px;
        li {
          padding: 10px;
          font-size: 14px;
          font-weight: 400;
          position: relative;

          &::before {
            content: '';
            background-image: url('../assets/images/ticks.png');
            position: absolute;
            left: -14px;
            top: 13px;
            background-size: contain;
            width: 27px;
            height: 22px;
            background-repeat: no-repeat;
            //z-index: 99999;
            font-weight: 600;
          }
        }
      }
    }

    .table-section {
      border: 1px solid #5E1D14 !important;
      border-radius: 12px !important;
      overflow: hidden;
      width: 65%;
      margin: 20px auto;
    }

    .swiper-button-prev {
      background: $white;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      // border: 1px solid $primary;
      left: 0 !important;
      cursor: pointer;

      img {
        height: 16px;
        // transform: rotate(180deg);
      }
    }

    .swiper-button-prev {
      background: $white;
      height: 35px;
      width: 35px;
      border-radius: 50%;

      left: 0 !important;
      cursor: pointer;

      img {
        height: 16px;
        // transform: rotate(180deg);
      }
    }

    .swiper-button-next {
      background: $white;
      height: 35px;
      width: 35px;
      border-radius: 50%;

      right: 0 !important;
      cursor: pointer;

      img {
        height: 16px;
      }
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      display: none !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
      background-color: #03933e;
      background-repeat: no-repeat;
      height: 60px;
      line-height: 20px;
      background-size: 40% 40% !important;
      background-position: center;
      // transition: all .3s ease-in-out 0s;
    }

    .swiper-button-prev {
      border-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      img {
        width: 12px;
        position: relative;
        right: 3px;
      }


    }

    .swiper-button-next {
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      img {
        width: 12px;
      }

      // &:hover {
      //   background-color: $primary;
      //   background-repeat: no-repeat;
      //   background-size: 40% 40% !important;
      // }
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 0px !important;
      right: auto;
      top: 150px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 0px !important;
      left: auto;
      top: 150px;
    }
  }

}

.govn_box_flex {
  width: 100%;
  display: flex;
  height: 100%;
  margin: 20px 0 0px 0;

  .govn_box {
    width: 31%;
    height: 329px;
    margin-right: 25px;
    border-radius: 8px;
    background: #03933e;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
    clip-path: polygon(100% 0%, 100% 100%, 50% 95%, 0% 100%, 0 48%, 0% 0%);

    .govn_img {
      width: 100%;
      max-height: 100%;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 207px;
        object-fit: cover;
      }

      .news_type {
        height: 32px;
        line-height: 32px;
        padding: 0 15px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        position: absolute;
        top: 0;
        color: #fff;

        &.bg_one {
          background: #ef9d00;
        }

        &.bg_two {
          background: #05D000;
        }

        &.bg_three {
          background: #7E00D7;
        }
      }
    }

    .govn_content {
      padding: 16px 14px 17px 39px;


      h3 {
        font-size: 20px;
        font-weight: 550;
        letter-spacing: 0;
        margin: 0 0 10px 0;
        line-height: 23px;
        text-transform: capitalize;
        color: $white;

      }

      p {
        font-size: 13px;
        letter-spacing: 0;
        line-height: 22px;
        font-weight: 400;
        color: #21262c;
        min-height: auto;
        overflow: hidden;
        margin-bottom: 20px;
      }


      .read-btn {
        min-width: 150px;
        height: 34px;
        line-height: 30px;
        border-radius: 7px;
        background: #da3927;
        color: #fff;
        transition: all .5s ease-in-out 0s;
        margin-bottom: 20px;

        // &:hover {
        //   background: $primary;
        //   border-color: $primary;

        //   .mat-button-wrapper {
        //     color: #fff;
        //   }
        // }
      }
    }
  }
}


.about-sect {

  background-image: url(../assets/images/background.png);

  h2 {
    font-size: 28px;
    font-weight: 500;
    text-align: start;
    letter-spacing: 0;
    margin: 0px 0px 0px 10px;
    padding: 0;
    color: #1a9c4d;
  }

  .profile-content {
    margin-top: 40px;

    img {
      width: 100%;
      height: 200px;
      margin-top: 10px;
      object-fit: cover;
    }

    p {
      word-spacing: 2px;

      span {
        color: #da3927;
        letter-spacing: 1px;
      }
    }

    width: 98%;
    clear: both;
    padding: 3px 0px 37px;
    margin: 0 auto;

    h1 {
      font-size: 30px;
      font-weight: 600;
    }

    h2 {
      // padding-bottom: 10px;
      font-weight: 700;
      font-size: 26px;
    }

    h3 {
      font-size: 23px;
      font-weight: 600;
    }

    h4 {
      font-size: 19px;
      font-weight: 600;
    }

    h5 {
      font-size: 16px;
      font-weight: 600;
    }

    h6 {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.2px;
      text-transform: capitalize;
      // width: 80%;
      // text-align: center;
      // margin: 50px 0 0 0;

    }

    p {
      // margin-bottom: 30px;
      font-size: 20px;
      line-height: 21px;
      width: 100%;
      letter-spacing: 0.2px;
      margin: 25px 0px 0px 0px;
    }

    ul {
      padding: 10px;

      // padding-left: 50px;
      li {
        padding: 13px 0px 11px 35px;
        font-size: 18px;
        font-weight: 400;
        position: relative;

        &::before {
          content: "";
          background-image: url('../assets/images/ticks.png');
          position: absolute;
          left: 3px;
          top: 13px;
          background-size: contain;
          width: 26px;
          height: 21px;
          background-repeat: no-repeat;
          font-weight: 600;
          //z-index: 99999;

        }
      }
    }
  }

}

.village-perf {
  background-image: url(../assets/images/background.png);

  .custom_heading {
    h2 {
      color: #31a359;
      padding-bottom: 30px;
      text-align: center;
    }
  }

  .village_about {
    display: flex;

    .about-point {

      .check {
        margin-bottom: 35px;


        p {
          font-size: 13px;
          font-weight: 600;
          text-align: end;

          img {
            width: 25px;
            height: 21px;
            position: relative;
            left: 5px;
            top: 4px;
          }
        }
      }

      .perct {
        background: #03933e;
        border-radius: 50%;
        height: 180px;
        width: 180px;
        margin-bottom: 45px;
        position: relative;

        &::before {
          border-bottom: 1px solid gray;
          height: 155px;
        }

        h2 {
          color: white;
          position: absolute;
          left: 43px;
          top: 70px;
          font-weight: 600;
        }
      }

    }

    .pints {


      p {
        font-size: 13px;
        font-weight: 600;

        img {
          width: 25px;
          height: 21px;
          position: relative;
          right: 5px;
          top: 4px;
        }
      }
    }

    .pinos {

      p {
        font-size: 14px;
        font-weight: 600;

        img {
          width: 25px;
          height: 21px;
          position: relative;
          right: 5px;
          top: 4px;
        }
      }
    }
  }
}


.histoc {
  .custom_heading {
    h2 {
      color: #03933e;
      padding-bottom: 35px;
      font-size: 33px;
    }
  }

  margin: 0 auto;
  background-image: url(../assets/images/background.png);
  padding: 50px 15px 45px 15px;

  .members {

    h1 {
      color: $black !important;
      font-weight: 900 !important;
      margin-bottom: 10px !important;
      padding-left: 15px;
    }

    span {
      font-size: 20px;
      color: $primary;
      font-weight: 500;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      margin: 15px;
      text-align: justify;
    }

    .hos {
      img {
        width: 65%;
      }
    }
  }
}

.nar-map {
  .custom_heading {
    h2 {
      color: #03933e;
      padding-bottom: 35px;
      font-size: 33px;
    }
  }

  margin: 0 auto;
  background-image: url(../assets/images/background.png);
  padding: 50px 15px 45px 15px;

  .route {
    text-align: center;

    img {
      width: 100%;
    }
  }
}

.oraganic {
  margin: 0 auto;
  background-image: url(../assets/images/background.png);
  padding: 50px 15px 45px 15px;

  .custom_heading {
    h2 {
      color: #03933e;
      padding-bottom: 35px;
      font-size: 33px;
    }
  }

  .organic-video {
    video {
      width: 100%;
      height: 420px;
      object-fit: cover;
    }
  }

  .oraginc-img {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .bunton {
    .read-btn {
      min-width: 150px;
      height: 34px;
      line-height: 30px;
      border-radius: 7px;
      background: #d94132;
      color: #fff;
      transition: all 0.5s ease-in-out 0s;
      margin: 20px;
    }
  }
}

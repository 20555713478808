.form-section {
    width: 100%;
    .custom-form-group {
      margin: 0px;
      .custom-label {
        margin: 0 0 8px 0;
        display: block;
        font-weight: 300;
        font-size: 13px;
        text-align: left;
      }
      .custom-form-control {
        width: 100%;
        .mat-form-field {
          width: 100%;
          .mat-form-field-wrapper {
            margin: 0px !important;
            padding-bottom: 0px !important;
            .mat-form-field-flex {
              outline: 0px !important;
              &:hover,
              &:focus {
                .mat-form-field-outline {
                  color: rgba(0, 0, 0, 0.12) !important;
                  outline: 0px !important;
                  .mat-form-field-outline-end {
                    outline: 0 !important;
                  }
                }
              }
              .mat-form-field-outline {
                outline: 0px !important;
                border-radius: 6px !important;
                .mat-form-field-outline-start {
                  min-width: 0;
                  border-left-style: none;
                }
                .mat-form-field-outline-end {
                  border-radius: 4px;
                  border-left-style: solid;
                  border-color: #ccced2;
                  outline: 0;
                }
              }
              .mat-form-field-infix {
                padding: 14px 14px !important;
                border: 0px !important;
                input.mat-input-element {
                  height: 18px;
                }
                .mat-select-placeholder {
                  color: #222a41 !important;
                }
              }
            }
          }
        }
        .mat-focused {
          .mat-form-field-outline {
            .mat-form-field-outline-end {
              border: 1px solid $primary;
            }
          }
        }
        .mat-radio-label {
          margin-right: 45px;
        }
        .mat-radio-checked {
          .mat-radio-outer-circle {
            border-color: $primary;
          }
          .mat-radio-inner-circle {
            background-color: $primary;
          }
        }
        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #222a41 !important;
          /* Firefox */
          font-weight: 400 !important;
          text-transform: capitalize;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #222a41 !important;
          font-weight: 400 !important;
          text-transform: capitalize;
        }
        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #222a41 !important;
          font-weight: 400 !important;
          text-transform: capitalize;
        }
        .mat-select-arrow {
          position: relative;
          top: 7px;
        }
        .mat-icon {
          height: 30px;
          width: 30px;
        }
        .arrow_icon {
          width: 20px;
        }
      }
      
    }
  }
  
  .mat-checkbox {
    margin: 5px 10px 5px 0px;
    .mat-checkbox-label {
      font-size: 12px;
    }
    .mat-checkbox-frame {
      background-color: transparent;
      transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1);
      border-width: 1px;
      border-style: solid;
    }
    .mat-checkbox-frame {
      border-color: rgb(177 177 177);
      border-width: 1px;
    }
    .mat-checkbox-inner-container-no-side-margin {
      margin-left: 18px;
    }
  }
  .mat-checkbox-checked {
    &.mat-accent {
      .mat-checkbox-background {
        background-color: #4dad6a !important;
      }
    }
    .mat-checkbox-label {
      color: #4dad6a;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 1000s ease-in-out 0s;
  }
  
  .mat-select-panel-wrap {
    background-color: $white;
    border: 1px solid #ccced2;
    border-radius: 4px;
    width: 100%;
    position: relative;
    top: 40px;
    left: 5%;
  }
  
/* ------------------------------ BREAK-POINTS ------------------------------ */
$breakpoint-xs-sm: "screen and (min-width:240px) and (max-width: 767px)";
$breakpoint-xs: "screen and (min-width:240px) and (max-width: 599px)";
$breakpoint-sm: "screen and (min-width:600px) and (max-width: 767px)";
$breakpoint-md: "screen and (min-width:768px) and (max-width: 959px)";
$breakpoint-md-lg: "screen and (min-width:960px) and (max-width: 991px)";
$breakpoint-lg: "screen and (min-width:992px) and (max-width: 1199px)";
$breakpoint-mac: "screen and (min-width:1200px) and (max-width: 1280px)";
$breakpoint-xl: "screen and (min-width:1281px) and (max-width: 1365px)";
$breakpoint-laptop: "screen and (min-width:1366px) and (max-width: 1366px)";
$breakpoint-xxl: "screen and (min-width: 1370px)";

/* ------------------ MOBILE-PHONES-VERTICAL-HORIZONTAL ------------------ */
@media #{$breakpoint-xs-sm} {

  // MOBILE-MENU-SCSS
  .master_navbar_sec {
    display: none !important;
  }

  .master-navbar-section {
    display: none !important;
  }

  .latest_news_sec {
    margin: 0px 0px 0px 0px !important;

    .new_flex {
      .title {
        width: 75% !important;
      }
    }
  }

  .mobile-top-menu-show {
    display: block !important;

    .lang_chan {
      padding: 10px 10px;

      .mat-button-toggle-appearance-standard {
        .mat-button-toggle-label-content {
          color: black;
          background: white;

          &:hover {
            background:#d94132;
            color: #fff;
          }
        }

        &.mat-button-toggle-checked {

          .mat-button-toggle-button {
            .mat-button-toggle-label-content {
              background:#03933e;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .document_pins_sec {
    .document_pins_row {
      .document_pins_leftcolumn {
        .model_village_sec {
          margin: 0px 0px 0px 0px !important;
        }

        .document_box_flex {
          flex-direction: column !important;

          .document_box {
            width: 100% !important;
            margin-right: 0px !important;
          }
        }
      }
    }
  }

  .latest_photo_sec {
    .latest_photo_row {
      .latest_photo_column {
        &.left {
          .galleries_banner {
            .gallery_list {
              ul {
                flex-direction: column !important;

                li {
                  img {
                    width: 100% !important;
                    height: 100% !important;
                  }
                }
              }
            }
          }
        }

        &.right {
          .feed-iframe-box {
            width: 100% !important;
          }
        }
      }
    }
  }

  .e_governance_sec {
    .e_governance_row {
      .e_governance_column {
        .links-section {
          .bottom_link {
            margin: 0px 0 0 0 !important;

            li {
              width: 100% !important;
              padding-left: 0px !important;
              margin-left: 0px !important;
            }
          }

          ul {
            .line_overlay {
              background-image: none !important;
            }

            li {
              width: 100% !important;
              margin-left: 0px !important;
            }
          }
        }
      }
    }
  }

  .village_vision_sec {
    .village_vision_row {
      .village_vision_column {
        &.public_places_column {
          .public_places_box {
            .public_places_head {
              h4 {
                font-size: 15px !important;
              }
            }
          }
        }
      }
    }
  }

  .weekly_newsletter {
    .newsletter_banner {
      .banner_container {
        .content {
          h2 {
            display: none !important;
          }

          p {
            display: none !important;
          }
        }
      }
    }

    .footer-notify-input {
      width: 100% !important;
      margin: 49px auto 0 auto !important;

      .icon-btn {
        //margin: -4px -10px 3px 0px;
      }
    }
  }

  .document_pins_sec {
    .document_pins_row {
      .document_pins_leftcolumn {
        .model_village_sec {
          .model_village_banner {
            margin-right: 0px !important;

            .banner_container {
              .content {
                width: 100% !important;
                margin-bottom: 9px !important;

                h2 {
                  line-height: 25px !important;
                }
              }
            }
          }
        }
      }

      .document_latest_sec {
        margin: 0px 0 0px 0 !important;
      }
    }
  }

  .banner-sec {
    .swiper-container {
      height: 280px !important;

      .swiper-wrapper {
        .swiper-slide {
          .banner_overlay {
            top: 0px !important;
          }
        }
      }
    }

    .banner_container {
      .banner-content {
        width: 100% !important;

        h1 {
          font-size: 31px !important;
          line-height: 55px !important;
        }
      }
    }
  }

  .progressive_initiatives_sec {
    .initiatives_banner {
      padding-top: 0px !important;
    }
  }

  .innerpage_sec {
    .wrapper {
      .table-section {
        overflow: hidden;
        width: 100% !important;
        td{
        padding: 8px 8px;
        }
      }

      .widhun {
        p {
          width: 100% !important;
          margin-bottom: 0px !important;
          padding: 8px 8px;
        }

        ul {
          li {
            padding: 16px 16px 0px !important;

            &::before {
              z-index: -1 !important;
            }
          }
        }
      }
    }
  }

  .innerpage_sec {
    .wrapper {
      .widhun {
        ul {
          padding: 10px 10px 10px 20px !important;

          li {
            &::before {
              left: -20px !important;
              top: 13px !important;
            }
          }
        }
      }
    }
    .govn_box_flex{
      display: block !important;
    }
    
  }
table{
  overflow-x: auto;
  display: block;
  width: 100% !important;
  td{
    padding: 8px 8px;
  }
}
  .innerpage_bottom_sec {
    .initiatives_top {
      background-position: center !important;
      background-size: contain !important;
    }
  }
  .banner{
    .testimonial{
        border-left: 0 !important;
        border-right: 0 !important;
        border-top: 0 !important;
        z-index: 1!important;
        position: absolute !important;
        top: 0 !important;
        left: auto!important;
        height: auto!important;
        padding: 15px!important;
        .buttons-baner{
          display: block !important;
        }
    }
    .testimonial:after {
      border-bottom: 0 !important;
    }
    .testimonial:before {
      border-bottom: 0 !important;
    }
    .title{
      position: absolute !important;
    top: 37px !important;
    left: 16px !important;
    }
  }
    .footer {
      .form-section{
        .custom-form-group{
          .custom-form-control{
            .mat-form-field {
              .mat-form-field-wrapper{
                .mat-form-field-flex{
              padding: 5px 20px;
            }
          }
        }
      }
    }
  
    }   
  }
  
  .place_box {
    width: 100%;
    height: 100% !important;
    background-color: #03933e;
    clip-path: none!important;
  
    .read-btn {
      background: #d94132;
      color: white;
      margin: 20px auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    // .public_places_head {
    //   height: 60px;
    //   line-height: 60px;
    //   text-align: center;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
  
  
      h4 {
        color: $white;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        letter-spacing: 0;
        margin: 0;
        padding: 0;
      }
    }
  
    .media {
      height: 100%!important;
      display: block;
      width: 100%!important;
  
      img {
        width: 100%!important;
      height: 100%!important;
      object-fit: contain;
  
      }
    }
  
    .media-body {
      background: #95d3ae;
      width: 100%!important;
      position: relative!important;
      left: 0!important;
      height: 100%!important;
  
      h1 {
        font-size: 18px;
        text-align: center;
        font-weight: bold;
        position: relative;
        top: 16px;
        color: #1b9e51;
      }
  
      .read-btn {
        background: #03933e;
        color: white;
        margin: 20px auto!important;
        display: flex;
        align-items: center!important;
        justify-content: center!important;
      }
    }
  
    .media-bodys {
      background: #95d3ae;
      width: 100%!important;
      position: relative!important;
      right: 0!important;
      top: 0!important;
      height: 100%!important;
  
      h1 {
        font-size: 18px;
        text-align: center;
        font-weight: bold;
        position: relative;
        top: 16px;
        color: #1b9e51;
      }
  
      .read-btn {
        background: #03933e;
        color: white;
        margin: 20px auto!important;
        display: flex;
        align-items: center!important;
        justify-content: center!important;
      }
    }
  
    .media-bods {
      background: #95d3ae;
      width: 100%!important;
      position: relative!important;
      right: 0!important;
      bottom: 0!important;
      height: 100%!important;
  
      h1 {
        font-size: 18px;
        text-align: center;
        font-weight: bold;
        position: relative;
        top: 16px;
        color: #1b9e51;
      }
  
      .read-btn {
        background: #03933e;
        color: white;
        margin: 20px auto!important;
        display: flex;
        align-items: center!important;
        justify-content: center!important;
      }
    }
    .village-perf{
      .village_about{
        display: block;
      }
      // .pints{
      //   position: relative;
      //   top: 0 !important;
      // }
      // .pinos{
      //   position: relative;
      //   top: 0 !important;
      // }
    }
    .single-video {
      video {
        width: 100% !important;
      }
      img{
        width: 100% !important;
      }
    }
    .members {
      img{
        width: 100%;
      }
      .hos{
        img{
          width: 100% !important;
        }
    }
  }
  .home_banner .banner_img .banner_img {
    width: 100%;
    height: 170px;
    object-fit: cover;
}
.footer .sub {
  margin: auto;
width: auto;
}
}



/* ------------------------------ MOBILE-PHONES-VERTICAL -------------------- */
@media #{$breakpoint-xs} {
  .home_banner .banner_img .banner_img {
    width: 100%;
    height: 170px !important;
    object-fit: cover;
}
.home_banner .banner_container .banner_content h1 {
  font-size: 18px !important;
  span {
    font-size: 23px !important;
  }
 }
 .adrash-gram .steps .step-img img {
   left: 11px !important;
   object-fit: contain !important;
 }
}

/* ------------------------------ MOBILE-PHONES-HORIZONTAL ---------------- */
@media #{$breakpoint-sm} {
  .home_banner .banner_img .banner_img {
    width: 100%;
    height: 170px !important;
    object-fit: cover;
}
.home_banner .banner_container .banner_content h1 {
 font-size: 18px !important;
 span {
  font-size: 23px !important;
}
}
}

/* --------------------- I-PAD-HORIZONTAL-LAPTOPS-TABLETS ------------------- */
@media #{$breakpoint-md} {
  .mobile-top-menu-show {
    display: block !important;

    .mobile-logo-box {
      img {
        width: 35%;
      }
    }
  }

  .master_navbar_sec {
    display: none;
  }

  .master-navbar-section {
    display: none !important;
  }

  .latest_photo_sec {
    .latest_photo_row {
      flex-direction: column !important;

      .latest_photo_column {
        &.right {
          .feed-iframe-box {
            width: 100%;

            iframe {
              width: 74%;
              margin: 0 auto;
            }
          }
        }

        &.left {
          .galleries_banner {
            overflow: auto;
          }
        }
      }
    }
  }

  .document_pins_sec {
    .wrapper {
      .document_pins_row {
        flex-direction: column !important;
      }
    }
  }

  .village_vision_sec {
    .village_vision_row {
      flex-direction: column !important;
    }
  }

  .e_governance_sec {
    .e_governance_row {
      .e_governance_column {
        .links-section {
          .bottom_link {
            margin: 0px 0 0 0;

            li {
              width: 100%;
              padding-left: 0px;
              margin-left: 0px;
            }
          }

          ul {
            .line_overlay {
              background-image: none;
            }

            li {
              width: 100%;
              margin-left: 0px;
            }
          }
        }
      }
    }
  }

  .banner-sec {
    .banner_container {
      bottom: 40%;
    }
  }

  .document_pins_sec {
    .document_pins_row {
      .document_pins_leftcolumn {
        .document_box_flex {
          .document_box {
            width: 33%;
            margin-right: 9px;
          }
        }

        .model_village_sec {
          margin: 0px 0px 0px 0px;

          .model_village_banner {
            margin: 0px 0px 0px 0px;
          }
        }
      }
    }
  }

  .innerpage_sec {
    .wrapper {
      .table-section {
        overflow: hidden;
        width: 100% !important;
        display: block;
      }
  }
}
.place_box {
  img{
    width: 100% !important;
  }
  .media-bods {
    background: #95d3ae;
    width: 175px;
    position: absolute;
    right: 32% !important;
    bottom: 81px !important;
    height: 95px;
}
.media-bodys {
  background: #95d3ae;
  width: 25% !important;
  position: absolute !important;
  right: 30% !important;
  top: 60% !important;
  height: 106px !important;
}
  }
  .master-navbar-section .main-top-navbar .header-main .header-bottom-navbar .nav_ul li a {
    font-size: 11px !important;
  }
  .footer .footer_links .ft-text img {
  object-fit: contain;
  }
  .banner .testimonial:before {
    border-bottom: 3px solid white;
    position: absolute;
    top: 0;
    right: 80%;
}
.banner .testimonial {
  left:14% !important;
}
.adrash-gram .steps {
margin: 0px !important;
}
.adrash-gram .steps .step-img {
  /* margin-right: 5px; */
  background-color: #1a9c4d;
  width: 26%;
}
.adrash-gram .steps .step-img {
  width: 69px !important;
}
.adrash-gram .steps h6 {
font-size: 13px !important;
}
}

/* --------------------- MEDIUM-LARGE-SCREEN ------------------- */
@media #{$breakpoint-md-lg} {
  .master-navbar-section .main-top-navbar .header-main .header-bottom-navbar .nav_ul li a {
    font-size: 11px !important;
  }
}

/* --------------------- LARGE-SCREEN ------------------- */
@media #{$breakpoint-lg} {}

// /* --------------------- MAC-SCREEN ------------------- */
@media #{$breakpoint-mac} {
 
}

// /* --------------------- EXTRA LARGE SCREENS ------------------- */
@media #{$breakpoint-xl} {}

/* ------------------------ 240-767--RESPONSIVE ----------------------- */
@media screen and (max-width: 767px) {
  
}

// ABOVE-LAPTOP
@media screen and (min-width: 1370px) {}

@media only screen and (min-width: 1600px) {
  .wrapper {
    max-width: 1400px;
    margin: 0 auto;
  }
.home_banner .banner_img .banner_img {
    width: 100%;
    height: 500px;
    object-fit: cover;
}
  .adrash-gram .steps .step-img img {
    width: 60%;
    height: 63%;
    position: relative;
    left: 20px;
    top: 16px;
    object-fit: contain;
}
.place_box .media-bodys {
right: 17% !important;
}
.place_box .media-bods{
  right: 17% !important;
}
}

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular11-primary: mat-palette($mat-indigo);
$angular11-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$angular11-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$angular11-theme: mat-light-theme((
  color: (
    primary: $angular11-primary,
    accent: $angular11-accent,
    warn: $angular11-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($angular11-theme);

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

@import 'master';

// SWIPER-SCSS
@import '~swiper/swiper-bundle.min.css';
//icons
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
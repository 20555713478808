.master_navbar_sec {
  z-index: 999;
  clear: both;

}

//HEADER SCSS
.master-navbar-section {
  z-index: 9999;
  width: 100%;

  .top_header {
    position: relative;
    background: url("../assets/images/logo_bg.png") no-repeat top center;
    background-size: cover;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgb(34, 139, 34, 0.7);
      width: 100%;
      height: 100%;
    }

    .wrapper {
      position: relative;
      padding: 15px 0;

      .top_head_img {
        img {}
      }

      select {
        position: absolute;
        top: 2px;
        right: 8px;
      }

      .overlay_left {
        content: '';
        background-image: url('/assets/images/top_left_overlay.png');
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: left;
        z-index: -1;
      }

      .overlay_right {
        content: '';
        background-image: url('/assets/images/top_right_overlay.png');
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: right;
        z-index: -1;
      }

      .top_header_logo {
        img {
          width: 200px;
          height: 100%;
          cursor: pointer;
        }
      }
    }
  }

  .main-top-navbar {
    &.sticky {
      .header-main {
        .header-bottom-navbar {
          .nav_ul {
            li {
              a {
                color: $white;

                &:hover {
                  color: $white;
                }

                &.router-link-active {
                  color: $white;

                  &::after {
                    background-color: #d94132;
                  }
                }

                &::after {
                  content: "";
                  background-color: #d94132;
                }
              }
            }
          }
        }
      }
    }
  }

  .main-top-navbar {
    z-index: 999;

    .logoHidden {
      display: none;
    }

    &.inner-header {
      .header-main {
        // background: $primary !important;
        // background-image: none !important;

        &:before {
          // content: ' ';
          // background: $primary !important;
          // background-image: none !important;
          // position: absolute;
          // right: 0px;
          // top: 0px;
          // bottom: 0px;
          // left: 0px;
          // background: linear-gradient(#fefefe 0%, #b0c4dd 100%);
          // opacity: 0.9;
          //     content: " ";
          // background: #044FB8 !important;
          // background-image: none !important;
          // position: absolute;
          // right: 0px;
          // top: 0px;
          // bottom: 0px;
          // left: 0px;
        }
      }

      .header-bottom-navbar {
        li {

          // color: $white !important;
          &.router-link-active {
            // color: #acb6fe !important;
          }
        }
      }
    }

    .header-main {
      height: 60px;
      line-height: 60px;
      top: 0;
      width: 100%;
      left: 0;
      right: 0;
      z-index: 9999;
      box-shadow: 0 1px 3px rgba(0, 0, 0, .11);
      transition: transform 1s cubic-bezier(.23, 1, .32, 1);
      background: #d94132;


      &:before {
        // content: ' ';
        // background-color: #fefefe;
        // background-image: linear-gradient(90deg, #fefefe 0%, #b0c4dd 100%);
        // position: absolute;
        // right: 0px;
        // top: 0px;
        // opacity: 0.8;
        // bottom: 0px;
        // left: 0px;
        // background: linear-gradient(#fefefe 0%, #b0c4dd 100%);
        // opacity: 0.9;
        // content: " ";
        // background-color: #fefefe;
        // background-image: linear-gradient(90deg, #fefefe 0%, #b0c4dd 100%);
        // position: absolute;
        // right: 0px;
        // top: 0px;
        // opacity: 0.8;
        // bottom: 0px;
        // left: 0px;
      }

      .header-row {
        width: 100%;
      }

      // .main-logo {
      //   width: 100%;
      //   height: 100%;
      //   display: flex;
      //   cursor: pointer;
      //   justify-content: flex-start;

      //   img {
      //     width: 100%;
      //     height: 100%;
      //     overflow: hidden;
      //     max-width: 300px;
      //   }
      // }

      .header-bottom-navbar {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;

        .nav_ul {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          li {
            position: relative;
            transition: all .5s ease-in-out;
            cursor: pointer;

            padding: 0;
            font-size: 14px;

            a {
              font-size: 13px;
              font-weight: 500;
              cursor: pointer;
              letter-spacing: 0px;
              color: $white;
              text-transform: capitalize;
              margin: 0px 0px 0 0px;
              height: 60px;
              line-height: 60px;
              display: block;
              position: relative;
              transition: all .5s ease-in-out;

              img {
                width: 100% !important;
                height: 25px;
                position: relative;
                top: 8px;
                left: 15px;
                color: #fff;

              }

              &::after {
                content: '';
                background-color: $white;
                height: 3px;
                border-radius: 4px;
                width: 100%;
                max-width: 100%;
                margin: 0 auto;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0px;
                transform: scaleX(0);
              }

              &:hover {
                &::after {
                  // transform: scaleX(1);
                  // transition: all .5s ease-in-out 0s;
                  color: $white;
                  background-color: #03933e;
                  padding: 0 15px;
                }

              }

              &.router-link-active {
                color: $white;
                background-color: #03933e;
                padding: 0 15px;


                // &::after {
                //   content: "";
                //   background-color: $white;
                //   height: 3px;
                //   border-radius: 4px;
                //   width: 100%;
                //   opacity: 1;
                //   transform: scaleX(1);
                // }
              }
            }

            // &:before {
            //   position: absolute;
            //   left: 0px;
            //   top: 0px;
            //   transform: translateY(10px);
            //   width: 100%;
            //   height: 2px;
            //   background: #FFF;
            //   content: "";
            //   opacity: 0;
            //   transition: all 0.3s;
            // }
            // &:after {
            //   position: absolute;
            //   left: 0px;
            //   width: 100%;
            //   height: 2px;
            //   background: #FFF;
            //   content: "";
            //   bottom: 0px;
            //   transform: translateY(-10px);
            //   opacity: 0;
            //   transition: all 0.3s;
            // }
            // .caret-icon {
            //   margin-left: 5px;
            // }
            &:last-child {

              // margin-left: 25px;
              .chat-icon {
                background: $primary;
                border-radius: 50%;
                width: 35px;
                height: 35px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 22px;
                }
              }

              .global-btn {
                img {
                  width: 15px;
                }
              }
            }

            &.router-link-active {
              color: $primary;
            }
          }
        }

      }

      &.shadow {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.37);
      }
    }

    &.sticky {
      position: fixed;
      top: 0;
      animation: slide-down 0.7s;
      opacity: 1;
      width: 100%;
      z-index: 9999;
      // background: #fff;
      cursor: default;
      user-select: none;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.37);


      &:before {
        content: ' ';
        opacity: 1;
        background-color: #d94132;
        //background-image: linear-gradient(90deg, #fefefe 0%, #b0c4dd 100%);
        position: absolute;
        right: 0px;
        top: 0px;
        bottom: 0px;
        left: 0px;
      }

      .outline-bottom {
        border-bottom: 0px !important;
      }

      .header-top {
        opacity: 0 !important;
        height: 0px !important;
        -webkit-transition: all .7s ease-in-out;
        -moz-transition: all .7s ease-in-out;
        -ms-transition: all .7s ease-in-out;
        -o-transition: all .7s ease-in-out;
        transition: all .7s ease-in-out;
      }

      .header-main {
        .header-bottom-navbar {
          li {
            color: #1B2057;

            &.router-link-active {
              color: $primary;
            }
          }
        }
      }

      .top_header {
        display: none;
      }
    }

    .lang_chan {
      padding:10px ;
      .mat-button-toggle-appearance-standard {
        .mat-button-toggle-label-content {
          color: black;
          background: white;

          &:hover {
            background: $primary;
            color: #fff;
          }
        }

        &.mat-button-toggle-checked {

          .mat-button-toggle-button {
            .mat-button-toggle-label-content {
              background: $primary;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.lng-drop-down {
  .mat-menu-item {
    line-height: 25px !important;
    height: 30px !important;
  }
}

//MEGA-MENU-SCSS
.navbar_search {
  position: relative;

  .mega-menu {
    position: absolute;
    box-shadow: 0 2px 4px 0 #b5b5b5 !important;
    background-color: #fff;
    width: 100%;
    height: 460px;
    overflow: auto;
    display: block;
    top: 34px;
    right: 0px;
    z-index: 2;
    left: 0px;
    bottom: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: 0px auto;
  }

  .tabs-left {
    .menu-nav-tabs {

      // box-shadow: 0 1px 3px #b6b6b6!important;
      li {
        border-bottom: 1px solid #dedede;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        display: block;

        a {
          padding: 7px 0 7px 7px !important;
          line-height: 16px;
          display: block;
          letter-spacing: 0.5px;
          color: #555;
          border: 1px solid transparent;

          &:hover {
            background-color: #eaeaea;
            color: #222;
          }

          &.active {
            background-color: #eaeaea;
            color: #222;
          }
        }
      }
    }
  }

  .tabs-right {
    background-color: #eaeaea;

    .menu-nav-tabs {

      // box-shadow: 0 1px 3px #b6b6b6!important;
      li {
        border-bottom: 1px solid #dedede;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        display: inline-block;
        width: 50%;

        a {
          padding: 7px 0 7px 7px !important;
          line-height: 16px;
          display: block;
          letter-spacing: 0.5px;
          color: #555;
          border: 1px solid transparent;

          &:hover {
            background-color: #fff;
            color: #222;
          }

          &.active {
            background-color: #fff;
            color: #222;
          }
        }
      }
    }
  }
}

//Category Dropdown
.category-drop-down {
  position: relative;

  &.mat-elevation-z4 {
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.17);
    border: 1px solid $outline;
  }

  &.mat-menu-panel {
    min-width: 160px !important;
    min-height: auto !important;
    border-radius: 12px !important;
  }

  .mat-menu-content:not(:empty) {
    padding: 0px 0px 0 0px !important;
  }

  .mat-menu-item-submenu-trigger {
    padding-right: 10px !important;

    &::after {
      opacity: 0;
    }
  }

  .category-item {
    line-height: 36px !important;
    height: 36px !important;
    padding: 0 10px !important;
    display: flex;
    color: #666666;
    font-weight: 500;
    position: relative;
    align-items: center;
    justify-content: space-between;
  }
}

//Category Sub Drop Down
.sub-drop-down {
  &.mat-elevation-z5 {
    box-shadow: none !important;
  }

  &.mat-menu-panel {
    min-width: 500px !important;
    max-width: 280px;
    min-height: auto !important;
    border-radius: 12px !important;
  }

  .mat-menu-content {
    padding: 10px !important;
  }

  .sub-menu-body {
    .sub-menu-list {
      display: inline-block;

      .sub-menu-item {
        color: #666666;
        line-height: 36px !important;
        height: 36px !important;
        font-weight: 500;
        font-size: 14px;
        float: left;
        width: 25%;
      }
    }
  }
}

// .sub-drop-down+* .cdk-overlay-pane {
//   .cdk-overlay-connected-position-bounding-box {
//     top: 0px !important;
// }
// }
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// SIDE-NAV-SCSS
.nav-sidemenu {
  // background-color: #F8F8FF;
  width: 250px;
  padding: 1px 0px 20px 0;
  border-right: 1px solid #f2f4f9;
  box-shadow: 0 8px 10px 0 rgba(183, 192, 206, .2);
  z-index: 1030 !important;
  background: $white;
  transition: all .5s;

  .left-side-menu {
    .sidebar-menus {
      margin: 0px !important;
      padding: 0px !important;

      li {
        display: flex;
        align-items: center;
        height: 35px;
        padding-left: 5px;
        margin: 0px 0 0px 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        cursor: pointer;
        position: relative;
        transition: all 0.5s ease-in-out;
        border-bottom: 1px solid #EEEEEE !important;

        &:first-child {
          border-bottom: 1px solid $outline;
          border-radius: 0;
        }

        .menu-icon {
          position: relative;
          transition: all 0.5s ease-in-out;
        }

        &.router-link-active {
          background: $primary;

          // padding: 5px 0 5px 0;
          .menu-icon {
            // border: 1px solid $primary;
          }
        }

        &:hover {
          color: $primary !important;

          .menu-icon {
            // border: 1px solid $primary;
          }
        }

        .menu-icon {
          border-radius: 50%;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          margin-left: 15px;
          // width: 30px;
          // height: 32px;
          position: relative;
          transition: all 0.5s ease-in-out;

          img {
            width: 10px;
            position: relative;
            top: 1px;
          }
        }

        .menu-name {
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          font-size: 14px;
          // padding-left: 10px;
          // text-transform: uppercase;
        }

        // Expansion List
        &.expansion-list {
          height: auto;

          .mat-expansion-panel-body {
            padding: 0 !important;
          }
        }
      }

      .hvr-col-listitem {
        margin-bottom: 5px;

        .hvr-col-listitem-link {
          letter-spacing: 0;
          cursor: pointer;
          padding: 10px 5px 10px 10px;
          display: block;
          text-transform: capitalize;
          font-size: 14px;
          line-height: 15px !important;
          height: 36px !important;
          color: #434343;
          font-weight: 600;
          line-height: 14px;
          background: #fff;
          border-bottom: 1px solid #eee;
          position: relative;
          -webkit-transition: all 0.8s ease-in-out 0s;
          transition: all 0.8s ease-in-out 0s;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            background: $primary;
            color: #fff;
          }
        }
      }
    }
  }
}

.toggle-btn {
  padding: 0;
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  line-height: 30px;
  background: $white;
  border-radius: 4px;
  position: relative;
  border: 1px solid #EEEEEE;
  cursor: pointer;

  // MENU-BUTTONS
  &.menu-button {
    .menu-lines {
      transition: all 0.3s ease-in-out;
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      right: 3px;
      width: 25px;
      height: 15px;
      transform-origin: center;

      // z-index: -1;
      span {
        transition: all 0.2s ease-in-out;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 2px;
        width: 100%;
        height: 2px;
        background: $primary;
        display: block;
        left: 0;
        transform-origin: center;

        &:first-child {
          top: 0;
          transform: none;
        }

        &:last-child {
          top: auto;
          bottom: 0;
          transform: none;
        }
      }
    }

    &.open {
      .menu-lines {
        span {
          &:first-child {
            opacity: 0;
          }

          &:nth-child(2) {
            transform: translateY(-50%) rotate(-45deg);
            background: #FA5D50;
          }

          &:nth-child(3) {
            transform: translateY(-50%) rotate(45deg);
            background: #FA5D50;
          }

          &:last-child {
            opacity: 0;
          }
        }
      }
    }
  }

  .mat-icon {
    // color: $primary;
    font-weight: 500;
  }
}

//SCROLL TOP
.scroll-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #00843E !important;
  width: 40px;
  opacity: 0;
  height: 40px;
  z-index: 9999;
  text-decoration: none;
  border-radius: 4px;
  display: block;
  cursor: pointer;
  -webkit-transition: all .7s ease-in-out;
  -moz-transition: all .7s ease-in-out;
  -ms-transition: all .7s ease-in-out;
  -o-transition: all .7s ease-in-out;
  transition: all .7s ease-in-out;

  &.scroll-top-in {
    opacity: 1 !important;
    -webkit-transition: all .7s ease-in-out;
    -moz-transition: all .7s ease-in-out;
    -ms-transition: all .7s ease-in-out;
    -o-transition: all .7s ease-in-out;
    transition: all .7s ease-in-out;
  }

  i {
    color: $white;
    margin: 0;
    line-height: 40px;
    display: block;
    text-align: center;
    font-size: 35px;
    -webkit-transition: all .7s ease-in-out;
    -moz-transition: all .7s ease-in-out;
    -ms-transition: all .7s ease-in-out;
    -o-transition: all .7s ease-in-out;
    transition: all .7s ease-in-out;
  }

  &:hover {
    background: #000000;

    i {
      color: $white;
    }
  }
}

.nav_dropdown_menu {
  margin-top: 20px;
  border-radius: 12px !important;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 22px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    z-index: 9999;
  }

  // &:after {
  //     content:"";
  //     position: absolute;
  //     left: 4px;
  //     top: -22px;
  //     width: 0;
  //     height: 0;
  //     border-style: solid;
  //     border-width: 0 17px 17px 17px;
  //     border-color: transparent transparent #ffffff transparent;
  //     z-index:999
  // }
  .mat-menu-item {
    line-height: 40px !important;
    height: 40px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    position: relative;
    letter-spacing: 0.5px;
    position: relative;
    transition: all 0.5s ease-in-out;
    border-bottom: 1px solid #eee;
    padding: 0 40px 0 10px;

    i {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      color: #044FB8 !important;

      // padding-left:15px !important;
      i {
        right: 5px;
      }
    }

    &:last-child {
      border-bottom: 0px !important;
    }
  }

  .mat-menu-item-submenu-trigger {
    &:after {
      border-width: 0px !important;
    }
  }
}

.sub_menu_services {
  margin-top: 8px;
  margin-left: 0px;
  box-shadow: none;
  border-radius: 12px !important;
  border-left: 1px solid #eee;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  .mat-menu-content {
    .mat-menu-item {
      width: 100%;
      font-size: 13px !important;
      font-weight: 500 !important;
      height: 32px !important;
      letter-spacing: 0.5px;
      line-height: 32px !important;
      position: relative;
      transition: all 0.5s ease-in-out;
      border-bottom: 1px solid #eee;

      &:hover {
        color: #044FB8;
        padding-left: 20px;
      }

      &:last-child {
        border-bottom: 0px;
      }
    }
  }
}

.mat-menu-panel {
  overflow: visible !important;
}

// THIS IS FOR MOBILE-MENU-HIDE
.mobile-top-menu-show {
  display: none !important;
}

// MOBIL-MENU-SHOW & ACCORDION
.mobile-top-menu-show {
  .mobile-navbar-sec {
    width: 100%;
    position: relative;
    background-color: #fefefe;
    background-image: linear-gradient(90deg, #fefefe 0%,   #d94132 100%);
  
    &.sticky {
      position: fixed;
      top: 0;
      animation: slide-down 0.7s;
      opacity: 1;
      width: 100%;
      z-index: 99999;
      // background: #fff;
      cursor: default;
      user-select: none;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.37);

      &:before {
        content: ' ';
        opacity: 1;
        background-color: #fefefe;
        background-image: linear-gradient(90deg, #fefefe 0%,   #d94132 100%);
        position: absolute;
        right: 0px;
        top: 0px;
        bottom: 0px;
        left: 0px;
      }

      .outline-bottom {
        border-bottom: 0px !important;
      }

      .header-top {
        opacity: 0 !important;
        height: 0px !important;
        -webkit-transition: all .7s ease-in-out;
        -moz-transition: all .7s ease-in-out;
        -ms-transition: all .7s ease-in-out;
        -o-transition: all .7s ease-in-out;
        transition: all .7s ease-in-out;
      }

      .header-main {
        .header-bottom-navbar {
          li {
            color: #1B2057;

            &.router-link-active {
              color: $primary;
            }
          }
        }
      }

      .mobile-toolbar-toggler {
        span {
          background: $white !important;

          &::before {
            background: $white !important;
          }

          &::after {
            background: $white !important;
          }
        }
      }
    }

    &.inner-header {
      // background: $primary !important;
      // background-image: none !important;

      &:before {
        content: ' ';
        // background: $primary !important;
        background-image: none !important;
        position: absolute;
        right: 0px;
        top: 0px;
        bottom: 0px;
        left: 0px;
        background-image: linear-gradient(90deg, #fefefe 0%, $primary 100%) !important;
      }

      .header-bottom-navbar {
        li {
          color: $white !important;

          &.router-link-active {
            color: #acb6fe !important;
          }
        }
      }

      .mobile-toolbar-toggler {
        span {
          background: $white !important;

          &::before {
            background: $white !important;
          }

          &::after {
            background: $white !important;
          }
        }
      }
    }
  }

  .parent-drop {
    padding: 8px 0 8px 15px;
    font-weight: 500;
  }

  .parent-list-li {
    padding: 0px !important;
  }

  .mobile-logo-box {
    position: absolute;
    //top: 15px;
    //left: 15px;
    z-index: 999;
    width: 50%;

    img {
      width: 52%;
      padding-left: 15px;
      // margin-top: -10px;
    }
  }

  .mobile-accordion {
    position: relative;
    top: 0;
    left: 0px;
    padding-top: 0px;
  }

  .mat-expansion-panel {
    cursor: pointer !important;
    background: transparent;
    margin-bottom: 0px !important;
    padding-left: 0px;
    box-shadow: none;

    .toggler {
      &.mat-expansion-panel-header {
        display: inline;
        cursor: pointer !important;
        width: 36px;
        padding: 0 5px 0 0 !important;
        text-align: center !important;
        line-height: 0;

        &:hover {
          background: transparent !important;
        }

        .mat-expansion-indicator {
          display: none;
          padding: 0;
          width: 30px;
          height: 30px;
          flex-shrink: 0;
          line-height: 30px;
          background: $white;
          border-radius: 4px;
          text-align: center;
          margin-left: 5px;
        }

        .mat-content {
          display: block !important;

          .mat-expansion-panel-header-title {
            float: right !important;
            // margin-right: 0 !important;
            display: inline-block;
            border: 1px solid $white;
            padding: 5px;
            border-radius: 4px;
            margin-top: 20px;

            .sub-menu-heading {
              font-weight: 600;
              padding-right: 5px;
              font-size: 16px;
            }

            i {
              font-size: 18px;
              margin-left: 5px;
            }
          }
        }
      }
    }

    .mat-expansion-panel-header {
      padding: 0px !important;
    }

    .mat-expansion-indicator {
      display: none;
      padding: 0;
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      line-height: 30px;
      background: $white;
      border-radius: 4px;
      text-align: center;
      margin-left: 5px;
    }

    .mat-expansion-panel-body {
      padding: 0 0 0px 0 !important;
      border: 0 !important;
      border-top: 1px solid #ccc !important;
      background: #fff !important;
      margin-top: 0px;
      margin-left: 0px;

      .top-right-nav {
        width: 100%;
        justify-content: center !important;
        padding: 0;
        margin: 0;

        li {
          display: block;
          font-size: 14px;
          font-weight: 500;
          overflow: hidden;
          letter-spacing: 0.5px;
          color: $primary;
          text-transform: capitalize;
          padding: 8px 0 8px 15px;
          margin: 0px 0px 0 0px;
          position: relative;
          transition: all 0.5s ease-in-out;
          cursor: pointer;
          border-bottom: 1px solid #e8e8ec;

          &:last-child {
            border: 0px;
          }

          a {
            display: flex;
            justify-content: space-between;
            padding-right: 10px;
            color: #03933e;
            width: 100%;
            line-height: 24px;
            align-items: center;

            .arrow-img {
              width: 15px;
            }

            img {
              width: 30px;
              overflow: hidden;
              position: relative;
              left: 0;
            }
          }
        }
      }

      .mat-menu-item {
        width: 100%;
        display: block;
        font-size: 14px;
        font-weight: 400;
        overflow: hidden;
        letter-spacing: 0px;
        color: #044FB8;
        text-transform: capitalize;
        padding: 0px 0 0px 25px;
        margin: 0px 0px 0 0px;
        position: relative;
        border-bottom: 1px solid #eee;
        line-height: 38px;
        height: 38px;
      }
    }
  }
}

// MOBILE-TOOGLE-BTN
.mobile-toolbar-toggler {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  background: none;
  outline: none !important;
  width: 26px;
  height: 26px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: relative;
  left: 0px;

  span {
    display: block;
    position: absolute;
    top: 12px;
    height: 2px;
    min-height: 2px;
    width: 100%;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background: $white;

    &::before {
      background: $white;
      top: -8px;
      position: absolute;
      display: block;
      right: 0;
      width: 100%;
      height: 0px;
      min-height: 2px;
      content: "";
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }

    &::after {
      transition: all 0.4s ease;
      left: auto;
      right: 0px;
      width: 100%;
      background: $white;
      bottom: -8px;
      position: absolute;
      display: block;
      height: 0px;
      min-height: 2px;
      content: "";
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }
  }
}

.mobile-toolbar-toggler {
  &:hover {
    span {
      background: $white !important;

      &::before {
        width: 100% !important;
        background: $white !important;
      }

      &::after {
        width: 100% !important;
        background: $white !important;
      }
    }
  }
}

.arrow-icon {
  width: 12px;
  margin-left: 5px;
}

// BOTTOM-MENU-DROP-DOWN-SCSS
.menu-list {
  position: relative;
  list-style: none;
  background: #fefefe;
  box-shadow: 5px 8.66px 60px 0 rgba(50, 117, 208, 0.25);
  text-align: left;
  overflow: hidden;
  z-index: 999;
  animation-name: dropdown-fadeInLeft;
  border-radius: 4px;
  margin-left: -15px;

  li {
    display: block !important;
    width: 100%;
    float: left;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      border: none;
    }

    a {
      transition: all .3s ease-in-out;
      padding: 0px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 10px;

      &:hover {
        // background: #fff;
        // color: $primary;
        // padding-left: 25px;
      }

      span {
        font-size: 14px;
        font-weight: 500;
      }

      .inner-dropdown {
        img {
          width: 15px !important;
        }
      }
    }
  }

  .mat-accordion {}

  .inner-dropdown-ul {
    li {
      padding-left: 15px !important;
      margin-left: 15px !important;
    }
  }
}

.mat-expansion-panel {
  transition: none !important;
}

a {
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }
}

.inner-dropdown {
  img {
    width: 15px !important;
  }
}

.is-dropdown-submenu-parent {
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  a {
    // padding: 8px 0;
  }
}

.mat-expansion-panel-header {
  height: auto !important;
}

.service_dropdown_li {
  padding: 8px 0 8px 15px !important;
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

// STOCKTON-PAGE-CSS
.master_navbar_sec {
  z-index: 999;
  
  .top_header {
    .wrapper {
      position: relative;
      padding: 15px 0;

      .top_head_img {
        img {}
      }
      .lang_chan{
        padding-bottom: 10px !important;
      }

      .overlay_left {
        content: '';
        background-image: url('/assets/images/top_left_overlay.png');
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: left;
        z-index: -1;
      }

      .overlay_right {
        content: '';
        background-image: url('/assets/images/top_right_overlay.png');
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: right;
        z-index: -1;
      }

      .top_header_logo {
        img {
          width: 300px;
          height: 100%;
          cursor: pointer;
        }
      }
    }
    
  }


}

.footer {
  background-size: cover;
  background-image: linear-gradient(rgb(34, 139, 34, 0.7), rgb(34, 139, 34, 0.7)), url("../assets/images/footer.png");
  position: relative;
    padding-top: 40px;
    width: 100%;
    padding-bottom: 40px;
 

  img {
    margin-bottom: 20px;
    width: 90%;
  }

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   // background-color: rgb(34,139,34, 0.7);
  //   width: 100%;
  //   height: 100%;
  // }

  h3 {
    color: $white;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
    font-weight: 700;
  }

  .footer_links {
    // margin-bottom: 20px;

    ul {

      li {
        
        margin-bottom: 10px;

        a {
          color: $white;
        }

        p {
          &:first-child {
            font-weight: 700;
          }

          margin-bottom: 10px;
        }
      }
    }

    .footer-heading {
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 1.2px;
      position: relative;
      display: inline-block;
      letter-spacing: 1px;
      color: #fff;
      margin-bottom: 35px;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        margin: 5px 0px;
        width: 30px;
        height: 3px;
        border-radius: 6px;
        background: #e21d24;
      }
    }

    .ft-text {
      color: $white;
      text-align: left;

      p {
        font-size: 15px;
        font-weight: 300;
      }

      h6 {
        font-size: 15px;
        margin: 0px 0 15px 0;
        line-height: 24px;
       
      }
      img{
        width: 35px;
        height: 35px;
        margin-bottom: -9px;
        object-fit: cover;
    
        
      }
    }

    .address-lines {
      padding: 0px 0 0 0;
     
      h6 {
        font-size: 16px;
        font-weight: 500;
        margin: 0 0 5px 0;
        letter-spacing: 0.5px;
        display: flex;
        justify-content: space-between;
        padding: 0 10px 0 0;
       
      }

      .address-type {
        background: $primary;
        padding: 0 15px 0 15px;
        height: 20px;
        line-height: 20px;
        margin: 5px 0 10px 0;
        display: inline-block;
        border-radius: 12px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        color: #fff;
        margin-left: 0;
      }

      p {
        margin: 0 0 5px 0;
        font-weight: 500;
        font-size: 14px;
        color: #6E798A;
        line-height: 18px;
      }
     
    }
  
  
    p {
      margin: 4px 31px 11px 0;
      font-size: 12px;
      color: #fff;
      line-height: 17px;
    }
  }

  .form-section {
    margin-bottom: 40px;

    .custom-form-group {
      .custom-form-control {
        display: flex;
        border: 1px solid $white;
        border-radius: 25px;
        background-color: #9bc6ab;

        .mat-form-field {
          .mat-form-field-wrapper {
            .mat-form-field-flex {
              padding: 2px 8px;

              .mat-form-field-outline {
                .mat-form-field-outline-end {
                  border: 0px;
                }
              }

              .mat-form-field-infix {
                input {
                  color: $white !important;

                  &:-webkit-autofill {
                    -webkit-text-fill-color: $white;
                  }

                  &:-webkit-autofill:focus {
                    -webkit-text-fill-color: $white;
                  }

                  &::placeholder {
                    color: $white !important;
                  }

                  :-ms-input-placeholder {
                    color: $white !important;
                  }
                }
              }
            }
          }
        }
      }

    }

    .btn-white {
      background-color: #d94132;
      color: $white;
      border: 1px solid #9bc6ab;
      font-size: 16px;
      font-family: "Objective" !important;
      font-weight: 500;
      padding: 7px 5px;
      border-radius: 25px;
      margin: 5px;
      cursor: pointer;
      display: inline-block;
      text-align: center;
      outline: none;
      letter-spacing: 1px;

    }

  }
  .sub {
    width: 50%;
    margin: auto;
}

}

.translate_toggle {

  .mat-slide-toggle-bar {
    position: relative;
    width: 35px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 24px;
    top: 5px;
    margin: 0 8px;

  }

  .mat-slide-toggle-thumb {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid;
    box-shadow: none;
    background-color: #fff;
  }

  .mat-slide-toggle-thumb-container {
    top: .5px;
    left: 1px;
  }

  .translate_content {
    font-weight: 600;
    color: #AEAEAE;
    font-size: 15px;
  }

  .mat-slide-toggle-bar {
    background-color: rgb(1 146 63);
  }

  .mat-slide-toggle-thumb {
    background-color: #fff;
    border-color: #fff;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgb(4 148 63);
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #fff;
    border-color: #fff;
  }
}
.footer_sec {
  position: relative;
  .footer_overlay{
    background-image: linear-gradient(rgb(34, 139, 34, 0.7), rgb(34, 139, 34, 0.7)), url("../assets/images/e_governance_tractor.png");
    width: 100%;
    padding: 19px 0px;
  .copy_right {
      color: #fff;
      /* position: absolute; */
      /* bottom: 2px; */
      left: 75px;
      right: 0;
      font-size: 15px;
      font-weight: 400;
      opacity: 1;
      letter-spacing: 0;
      text-align: center;
      margin-bottom: 0px;
  
  }
}
}


.social_links {
  .footer_heading {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 20px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      margin: 5px 0px;
      width: 30px;
      height: 3px;
      border-radius: 6px;
      background: #e21d24;
    }
}
  ul {
    margin: 15px 0 0 0;
    display: flex;

    li {
      margin: 0 20px 0 0;
      cursor: pointer;
      .soci {
        position: relative !important;
        right: 32% !important;
      }
       
      a{
        img{
          width: 11%;
          margin: 0 5px 0 5px;
      }
        }
      }  
    }
}
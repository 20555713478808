
// COLORS-SCSS
@import 'colors';

// FONTS-SCSS
@import 'fonts';
/* ************************  PERFECT-SCROLL-BAR-SCSS **************************  */
.perfect-scrollbar>.ps.ps--active-y>.ps__rail-y {
  width: 5px !important;
}
.perfect-scrollbar>.ps.ps--active-y>.ps__rail-y.ps--clicking {
  width: 5px !important;
}
.ps__rail-y {
  width: 5px !important;
  &:hover {
    width: 5px !important;
  }
}
.ps__thumb-y {
  width: 5px !important;
  right: 0px !important;
}
.perfect-scrollbar>.ps.ps--active-y>.ps__rail-y {
  width: 5px !important;
}
.ps__thumb-x {
  width: 100% !important;
  opacity: 0 !important;
  right: 0;
}
button:focus {
  outline: 0 !important;
}
button {
  outline: 0 !important;
}
// MEDIA-SCSS
.media {
  display: flex;
  align-items: flex-start;
  .media-left {
    margin-right: 15px;
    display: table-cell;
    vertical-align: top;
    img {}
  }
  .media-body {
    flex: 1;
    display: table-cell;
    vertical-align: top;
    .media-heading,
    h4 {
      font-size: 16px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 5px;
      font-family: $font-family;
    }
  }
}
.swiper-pagination-bullet {
  &:focus {
    outline: 0 !important;
    border: 0px !important;
  }
}
// CUSTOM-MODAL
.mat-dialog-container {
  // padding: 0 !important;
  padding: 10px!important;
  background: transparent!important;
  box-shadow: none!important;
  overflow: hidden!important;
}
.custom-modal {
  .master-modal {
    .modal-dialog {
      max-width: 100%;
      border-radius: 25px;
      .mat-form-field-outline {
        color: #D6E0DC !important;
        background-color: $body-bg !important;
      }
      &.delete-dialog {
        .warning-text {
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          color: $light-primary-text;
          font-family: $font-family !important;
          span {
            color: $light-yellow;
          }
        }
      }
      .modal-content {
        border: 0;
        border-radius: 25px;
        background-color: transparent !important;
        position: relative;
        .modal-header {
          padding: 10px 15px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          background-color: $primary;
          // background-color: #eeeeeefa;
          border: none !important;
          display: -webkit-box;
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          align-items: center;
          .modal-title {
            margin-bottom: 0;
            line-height: 1.5;
            letter-spacing: 1.2px;
            font-size: 16px;
            font-weight: 500;
            // color: $light-primary-text
            color: $white;
          }
          .heading-title {
            margin-bottom: 0;
            line-height: 1.5;
            letter-spacing: 1.2px;
            font-size: 16px;
            font-weight: 600;
            color: $white;
          }
          .modal-close {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 20px;
            }
          }
        }
        .mat-dialog-content {
          padding: 0 0px;
          margin: 0 0px;
          max-height: 65vh;
          overflow: initial;
        }
        .modal-body {
          padding: 15px 15px 15px 15px;
          width: auto !important;
          background-color: $white;
        }
      }
    }
  }
  .modal-footer {
    display: flex;
    justify-content: flex-end !important;
    align-items: center !important;
    background: $white;
    padding: 4px 0 4px 0;
    .hint-text {
      color: $light-primary-text;
      margin: 0px;
    }
    .mat-button {
      margin-right: 5px !important;
      height: 28px;
      line-height: 26px !important;
      border: 1px solid;
      padding: 0 10px 0 10px !important;
      &.save-btn {
        background-color: $primary;
        border-color: $primary;
        .mat-button-wrapper {
          color: $white;
        }
      }
      &.cancel-btn {
        background-color: #68686840;
        border-color: #68686840;
        .mat-button-wrapper {
          color: $black;
        }
      }
    }
  }
}

// CUSTOM-SNACK-BAR-SCSS
.custom-snackbar {
  padding: 4px 10px !important;
  min-height: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  text-align: center !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  background-color: $primary !important;
  text-transform: capitalize !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 0;
  text-align: center !important;
  margin: 0 auto !important;
  width:40% !important;
  max-width: 40% !important;

  .mat-simple-snackbar {
    align-items: center !important;
    justify-content: center !important;
    text-transform: capitalize !important;
    height: 40px !important;

    span {
      text-align: center !important;
      align-items: center !important;
      justify-content: center !important;
      color: $white;
      text-transform: initial !important;
      height: 40px !important;
      line-height: 30px !important;
      font-weight: 300 !important;
      letter-spacing: 0.5px;
      font-size: 14px;
    }
  }
}